import { UseQueryOptions } from "@tanstack/react-query";
import { useFormContext, useWatch } from "react-hook-form";
import { api } from "../../../api";
import { AutoCompleteInput } from "../../../shared/validated-form-inputs/AutoCompleteInput";
import { TextInput } from "../../../shared/validated-form-inputs/TextInput";
import { IControlledFieldProps } from "./types";
import { Signpost } from "@mui/icons-material";
import styled from "@emotion/styled";
import { FormLabel } from "@mui/material";
import { SingleCitySearch } from "../../../shared/components/SingleCitySearch";

const Container = styled.div`
  display: grid;
  grid-gap: 1rem;
`;
const StreetNumberContainer = styled.div`
  display: flex;
  gap: 1rem;
`;
const StreetContainer = styled.div`
  flex: 2;
`;
const HouseNumberContainer = styled.div`
  flex: 1;
`;

function useSearchStreet(search: string, options: UseQueryOptions<string[]>, city: string) {
    return api.Query.useSearchStreetByCityQuery({ city, search }, options);
}

export function AddressInfoField({ control, isOptional }: IControlledFieldProps) {
    const { getValues, watch } = useFormContext();

    const initCity: string = getValues('addressInfo.city');
    const city = watch('addressInfo.city') || initCity;
    let label = "כתובת";
    if (isOptional) label += " (אם רלוונטי)";

    return (
        <Container>
            <FormLabel>{label}</FormLabel>
            <SingleCitySearch
                control={control}
                name="addressInfo.city"
                label="עיר"
            />
            <StreetNumberContainer>
                <StreetContainer>
                    <AutoCompleteInput
                        control={control}
                        disabled={!city}
                        name="addressInfo.street"
                        label="רחוב"
                        optionCategory={city}
                        useOptions={useSearchStreet}
                        optionToString={x => x}
                        icon={<Signpost />}
                    />
                </StreetContainer>
                <HouseNumberContainer>
                    <TextInput
                        name={"addressInfo.buildingNumber"}
                        label="מספר בית"
                        control={control}
                        rules={{ validate: value => !value || Number.parseInt(value) > 0 }}
                    />
                </HouseNumberContainer>
            </StreetNumberContainer>
        </Container>
    );
}