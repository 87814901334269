import styled from "@emotion/styled";
import { ChevronLeft } from "@mui/icons-material";
import { ITreeButtonProps, TreeButton } from "./TreeButton";
import { transientProps } from "../shared/components/transilientProps";

export const LabelContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;
export const Chevron = styled(ChevronLeft, transientProps)<{ $isOpen: boolean }>`
  transform: rotate(${p => p.$isOpen ? -90 : 0}deg);
  transition: transform .2s ease-in-out;
`;
export const ChildrenContainer = styled.div`
  border-left: 1px solid ${p => p.theme.palette.primary.main}15;
`;


const ButtonContainer = styled.div`
  margin: 0.25rem;
`;
type ISemanticButtonProps = {
    label: string;
    onClick: () => void;
    variant: 'text' | 'outlined' | 'contained';
} & ITreeButtonProps;
export const SemanticButton = ({label, isOpen, onClick, variant}: ISemanticButtonProps) => {
    const size = variant === 'text' ? 'medium' : 'large';
    return (
        <ButtonContainer>
            <TreeButton {...{ onClick, isOpen, variant, size }}>
                <LabelContainer><Chevron $isOpen={isOpen} /> {label}</LabelContainer>
            </TreeButton>
        </ButtonContainer>
    )
}