import { IControlledFieldProps } from "./types";
import { CheckboxGroupInput } from "../../../shared/validated-form-inputs/CheckboxGroupInput";
import { createCheckboxOptions } from "../fieldUtils";
import { useMemo } from "react";


export function AgeStagesField({ control }: IControlledFieldProps) {
    const options = useMemo(() => createCheckboxOptions([
        'kids',
        'teens',
        'adults',
        'seniors'
    ]), []);

    return (
        <CheckboxGroupInput
            {...{ options, control }}
            name="ageStages"
            label="מיועד ל..."
        />
    )
}