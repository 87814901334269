export const debounce = (fn: Function, delay: number) => {
    let timerId: any;
    return (...args: any[]) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => fn(...args), delay);
    }
};

export async function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}