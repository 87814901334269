import React from "react";

export default function SearchIcon() {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9192 20.1855C16.3347 20.3397 20.0393 16.8852 20.1935 12.4696C20.3477 8.05402 16.8931 4.34948 12.4775 4.19529C8.06196 4.04109 4.35742 7.49563 4.20323 11.9112C4.04903 16.3268 7.50357 20.0313 11.9192 20.1855Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.844 22.5333L17.6484 18.0342" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )

}