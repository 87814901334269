import { useController } from "react-hook-form";
import { TextField } from "@mui/material";
import { ITextInputProps } from "./common";

export function TextAreaInput({ name, label, helpText, control, rules }: ITextInputProps) {
    const {
        field,
        fieldState: { error }
    } = useController({ name, control, rules, defaultValue: "" });
    return (
        <TextField
            {...field}
            label={label}
            error={!!error}
            helperText={error?.message ?? helpText}
            required={!!rules?.required}
            minRows={3}
            multiline
        />
    );
}