import React from "react";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { humanizePhone, isPhoneMobile } from "../../utils/utils";
import { ActionButton } from "../styled-components/Buttons";
import { Phone, WhatsApp } from "@mui/icons-material";

type IAsyncFunc = () => Promise<void>;
type IContactByPhoneProps = {
    name: string;
    phone: string;
    whatsAppAnalyticFunc: IAsyncFunc;
    dialAnalyticFunc: IAsyncFunc;
    isOpened: boolean;
    setIsOpened: (value: boolean) => void;
}

export function ContactByPhoneDialog({
    name,
    phone,
    whatsAppAnalyticFunc,
    dialAnalyticFunc,
    isOpened,
    setIsOpened
}: IContactByPhoneProps) {
    const [isLoading, setIsLoading] = React.useState(false);

    async function sendAnalytics(analyticFunc: IAsyncFunc) {
        setIsLoading(true);
        await analyticFunc();
        setIsLoading(false);
    }

    function safeOpenUrl(url: string) {
        if(!window.open(url)) {
            window.location.href = url;
        }
    }

    async function dial() {
        await sendAnalytics(dialAnalyticFunc);
        safeOpenUrl(`tel:${phone}`);
    }

    async function whatsapp() {
        await sendAnalytics(whatsAppAnalyticFunc);
        safeOpenUrl(`https://wa.me/${phone}`);
    }

    const closeDialog = () => setIsOpened(false);

    return (
        <Dialog open={isOpened} onClose={closeDialog}>
            <DialogTitle>
                {name}
            </DialogTitle>
            <DialogContent>
                {humanizePhone(phone)}
            </DialogContent>
            <DialogActions>
                {isLoading
                    ? <CircularProgress />
                    : (<>
                        {isPhoneMobile(phone) &&
                            <ActionButton
                                variant="contained"
                                color="success"
                                onClick={whatsapp}
                                size="medium"
                                startIcon={<WhatsApp />}>
                              לכתוב בואצאפ
                            </ActionButton>
                        }
                        <ActionButton variant="outlined" onClick={dial} startIcon={<Phone />} size="medium">
                          לחייג
                        </ActionButton>
                    </>)}
            </DialogActions>
        </Dialog>
    )
}