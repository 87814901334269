import { api } from "../api";
import { CityField } from "../shared/components/geo-fields/CityField";
import { GeoItemsField } from "../shared/components/geo-fields/GeoItemsField";
import React from "react";

type ISearchLocationFieldProps = {
    geoItems: api.GeoItems[];
    setGeoItems: (geoItems: api.GeoItems[]) => void;
    serviceProvidedAt: api.ServiceProvidedAt[];
}
export function SearchLocationField({geoItems, setGeoItems, serviceProvidedAt}: ISearchLocationFieldProps) {
    return (
        serviceProvidedAt.includes(api.ServiceProvidedAt.CustomerPremises) && !serviceProvidedAt.includes(api.ServiceProvidedAt.BusinessPremises)
            ? <CityField
                onChange={value => setGeoItems(value ? [new api.GeoItems({ kind: api.GeoItemKind.City, value })] : [])}
                label="עיר" value={geoItems?.[0]?.value}
            />
            : <GeoItemsField onChange={setGeoItems} label="ערים / אזורים" value={geoItems} />
    )
}