import { IRecommendationsStatsItem } from "../api/axios-client";
import { Link } from "@mui/material";
import styled from "@emotion/styled";

const Container = styled.div`
  font-size: 1rem;
  margin: 0.25rem 0;
`;


type IBusinessTypeTreeProps = {
    businessType: IRecommendationsStatsItem;
}

export function BusinessTypeLeave({ businessType }: IBusinessTypeTreeProps) {
    const href = businessType.businesses
        ? `/?search=${businessType.name}`
        : `/recommend/?businessType=${businessType.name}`;
    const label = businessType.businesses
        ? `${businessType.plural} (${businessType.businesses})`
        : `${businessType.plural} - להוסיף המלצה`;

    return (
        <Container>
            <Link {...{ href }} variant="body1">{label}</Link>
        </Container>
    )
}