import { SyntheticEvent, useState } from "react";
import { Autocomplete, CircularProgress, InputAdornment, TextField } from "@mui/material";
import { useSearchBusinessTypes } from "./searchBusinessTypeLogic";
import SearchIcon from "../../../assets/SearchIcon";
import { IBusinessTypeSimplified } from "../../../business/business.schema";
import { trackEvent } from "../../../analytics";
import { debounce } from "../../utils/newUtils";

type ISearchFieldProps = {
    selectBusinessType: (businessType: IBusinessTypeSimplified) => void;
    defaultValue?: IBusinessTypeSimplified;
}

export function BusinessTypeSearchField({ selectBusinessType, defaultValue }: ISearchFieldProps) {
    const [searchValue, setSearchValue] = useState('');
    const businessTypes = useSearchBusinessTypes(searchValue, result => {
        trackEvent("Search Business Type",
            { searchValue, result }
        )
        if (result.length === 0) {
            trackEvent("Business type does not exist?", { searchValue });
        }
    });

    const queryAutocompleteDebounced = debounce((_: SyntheticEvent, searchValue: string, reason: string) => {
        setSearchValue(reason === "reset" ? '' : searchValue);
    }, 500);

    function selectValue(event: SyntheticEvent, value: IBusinessTypeSimplified | string | null) {
        if (value && typeof value !== 'string') {
            trackEvent("Select Business Type", { searchValue, selectdName: value.name, value });
            selectBusinessType(value);
        }
    }

    return (
        <Autocomplete
            freeSolo
            id="home-page-search-field"
            options={businessTypes.data || []}
            getOptionLabel={(option) => (option as IBusinessTypeSimplified).name}
            onChange={selectValue}
            onInputChange={queryAutocompleteDebounced}
            filterOptions={(x) => x}
            loading={businessTypes.isFetching}
            loadingText="טוען..."
            value={defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={"חיפוש לפי סוג עסק"}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="start">
                              {businessTypes.isFetching? <CircularProgress /> : <SearchIcon />}
                            </InputAdornment>
                        )
                    }}
                    error={businessTypes.isError
                    || (!!searchValue && businessTypes.data?.length === 0 && !(businessTypes.isFetching))}
                    helperText={
                        businessTypes.isError
                        ? 'משהו השתבש :('
                        : searchValue && businessTypes.data?.length === 0 && !(businessTypes.isFetching)
                        ? 'לא נמצאו סוגי עסקים לחיפוש זה' 
                        : "למשל: אינסטלטור, מספרה, וטרינר"}
                />
            )}
        />
    )
}
