import React from "react";
import { ISignupUser, IUser, IUserContext } from "./user.schema";
import { appInsights, trackException } from "../analytics";
import { User } from "../api/axios-client";


export const emptyUser: IUser = {
    id: '',
    firstName: '',
    lastName: '',
    mobilePhone: '',
    gender: 'm',
    groups: [],
    addressInfo: {}
}

export const UserContext = React.createContext<IUserContext>({ user: emptyUser, hasBusiness: false });

export async function startPhoneVerification(phone: string): Promise<{ id: string } | null> {
    const url = '/api/phone-verification/start';
    const body = { phone };

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    });
    if (response.ok) {
        return response.json();
    }
    return null;
}

export async function finalizeCodeToVerification(id: string, code: string): Promise<{ user: IUser }> {
    const url = '/api/phone-verification/verify';
    const body = { id, code };

    try {
        return await (await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })).json();
    } catch (e) {
        throw new Error('Invalid code')
    }
}

export async function signup(user: ISignupUser): Promise<{ user: User } | null> {
    const addressInfo = { city: user.address };
    const { address, ...userMinusAddress } = user;

    const url = '/api/user/signup';

    try {
        return await (await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...userMinusAddress, addressInfo })
        })).json();
    } catch (e) {
        trackException('Signup failed', e as Error);
        return null;
    }
}

export async function setNotificationStatus(allowingNotifications: boolean, systemSupported: boolean): Promise<void> {
    const url = '/api/user/update-notification-status';

    try {
        return await (await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ allowingNotifications, systemSupported })
        })).json();
    } catch (e) {
        trackException('Error setting notification status', e as Error);
    }
}