import { useQueries } from "@tanstack/react-query";
import { api } from "../../../api";

export function useSearchBusinessTypes(searchValue: string, onSuccess?: (result: api.SimplifiedBusinessType[]) => void) {
    return api.Query.useSearchBusinessTypeQuery({ keyword: searchValue || '' },
        {
            enabled: Boolean(searchValue?.length > 1),
            onSuccess,
        }
    );
}

export function useBusinessTypesByNames(names: string[]) {
    return useQueries({ 
        queries: names.map(name => ({
            queryKey: api.Query.searchBusinessTypeQueryKey(name), 
            queryFn: () => api.Client.searchBusinessType(name), 
            enabled: name?.length > 1,
            staleTime: Infinity,
        })) 
    });
}

