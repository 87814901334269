export function normalizePhone(phone) {
    return phone.replace(/\D/g, '');
}

function isPhoneLocal(phone) {
    return phone.startsWith(`0`);
}

export function internationalizePhone(phone) {
    phone = normalizePhone(phone);
    return isPhoneLocal(phone) ? `+972${phone.replace(/^0+/, '')}` : `+${phone}`;
}

export function humanizePhone(phone) {
    const localized = phone.replace('+972', '0');
    return `${localized.slice(0, -7)}-${localized.slice(-7)}`;
}

export function isPhoneValid(phone, isMobile = false) {
    return isNormalizedPhoneValid(normalizePhone(phone), isMobile);
}

//////// !!!MUST GET NORMALIZED PHONES!!!
function isNormalizedPhoneValid(phone, mustBeMobile = false) {
    const mobilePrefixes = ['50', '52', '53', '54', '55', '57', '58'];
    const stationaryPrefixes = ['2', '3', '4', '8', '9', '72', '73', '74', '77'];
    const prefixes = mustBeMobile ? mobilePrefixes : [...mobilePrefixes, ...stationaryPrefixes];
    return (phone.length === 10 || phone.length === 9) && prefixes.some((prefix) => phone.startsWith(`0${prefix}`))
        || (phone.length === 12 || phone.length === 11) && prefixes.some((prefix) => phone.startsWith(`972${prefix}`));
}

export function isPhoneMobile(phone) {
    return isPhoneValid(phone, true);
}