import { IBusinessWithRecommendations, ServiceProvidedAt } from "../../api/axios-client";
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { LanguageOutlined, LocationCityOutlined, MapsHomeWorkOutlined } from "@mui/icons-material";
import JSX = jsx.JSX;
import { IconAndTextInlineContainer } from "../../shared/components/styled-components/Containers";

const BusinessProvidedAtContainer = styled.div`
  display: flex; 
  gap: 1rem;
`;
const BusinessProvidedAtItemContainer = IconAndTextInlineContainer;

export const serviceProvidedAt = (business: IBusinessWithRecommendations): JSX.Element | null => {
    const btServiceProvidedAtSet: Set<ServiceProvidedAt> = new Set();
    business.businessTypes.forEach(bt => {
        bt.serviceProvidedAt.forEach(spa => btServiceProvidedAtSet.add(spa));
    })
    const btsServiceProvidedAt = Array.from(btServiceProvidedAtSet);
    if (btsServiceProvidedAt.length > 1 && business.serviceProvidedAt) {
        const map: Record<ServiceProvidedAt, JSX.Element> = {
            [ServiceProvidedAt.BusinessPremises]: (
                <BusinessProvidedAtItemContainer><LocationCityOutlined />בבית העסק</BusinessProvidedAtItemContainer>
            ),
            [ServiceProvidedAt.CustomerPremises]: (
                <BusinessProvidedAtItemContainer><MapsHomeWorkOutlined />בבית הלקוח</BusinessProvidedAtItemContainer>
            ),
            [ServiceProvidedAt.Online]: (
                <BusinessProvidedAtItemContainer><LanguageOutlined />אונליין</BusinessProvidedAtItemContainer>
            ),
        };
        return (
            <BusinessProvidedAtContainer>
                {business.serviceProvidedAt.map(spa => map[spa])}
            </BusinessProvidedAtContainer>
        );
    }
    return null;
};

export const commonFields = (business: IBusinessWithRecommendations): Partial<IBusinessWithRecommendations> => {
    const {
        id,
        name,
        businessTypes,
        phoneNumber,
        serviceProvidedAt,
        addressInfo,
        serviceArea,
        recommendations,
        fields,
        externalLink,
        ...fieldsToDisplay
    } = { ...business, ...business.fields };

    return fieldsToDisplay;
}