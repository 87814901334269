import { SyntheticEvent, useMemo, useState } from "react";
import { Autocomplete, Button, TextField } from "@mui/material";
import { IAutocompleteOption } from "../../types";
import { useSearchBusinessTypes } from "./searchBusinessTypeLogic";
import { IBusinessTypeSimplified } from "../../../business/business.schema";
import { trackEvent } from "../../../analytics";
import { Add } from "@mui/icons-material";
import styled from "@emotion/styled";
import { BusinessTypeAddedDialog } from "./BusinessTypeAddedDialog";
import { BusinessTypeAddPromptDialog } from "./BusinessTypeAddPromptDialog";
import { api, queryClient } from "../../../api";
import { debounce } from "../../utils/newUtils";
import { BusinessTypesFieldsSet } from "../../../api/axios-client";
import { Control, useController } from "react-hook-form";

const OptionContainer = styled.li`
  margin: 0.5rem 1rem;
`;

type ISearchFieldProps = {
    control: Control<any>;
    name: string;
}

interface IBusinessTypeSimplifiedWithSearch extends IBusinessTypeSimplified {
    searchValue?: string;
}

export function BusinessTypesSelectField({ control, name }: ISearchFieldProps) {
    const { field } = useController({ name, control, rules: {required: 'שדה זה הוא שדה חובה'} });
    const [searchValue, setSearchValue] = useState('');
    const businessTypes = useSearchBusinessTypes(searchValue);
    const addBusinessType = api.Query.useAddBusinessTypeMutation({  onSuccess: () => {
        // Invalidate all search cache
        queryClient.invalidateQueries(api.Query.searchBusinessTypeQueryKey('').slice(0, -1));
    }});
    const [isAddBusinessTypeDialogOpen, setIsAddBusinessTypeDialogOpen] = useState(false);
    const [customBusinessTypeName, setCustomBusinessTypeName] = useState<string>('');
    const closeAddBusinessTypeDialog = () => {
        setIsAddBusinessTypeDialogOpen(false);
        setCustomBusinessTypeName('');
    };

    const [isPromptDialogOpen, setIsPromptDialogOpen] = useState(false);
    const closePromptDialog = () => setIsPromptDialogOpen(false);

    const queryAutocompleteDebounced = debounce(setSearchValue, 500);


    function setResultTypes(types: IBusinessTypeSimplified[]) {
        field.onChange(types);
    }

    function handleAddBusinessType() {
        addBusinessType.mutate(new api.AddBusinessTypeBody( { name: customBusinessTypeName }), {
            onSuccess: res => {
                field.value = field.value || [];
                if (field.value.every((x: IBusinessTypeSimplified) => x.name !== res?.name)) {
                    setResultTypes([...field.value, res]);
                    trackEvent("Added New Business Type", { name: res?.name });
                    closePromptDialog();
                    setIsAddBusinessTypeDialogOpen(true);
                }
            }
        });
    }

    function selectValue(_: SyntheticEvent, newValue: IBusinessTypeSimplifiedWithSearch[]) {
        const businessTypeToAdd = newValue.find(x => x.searchValue);
        if (businessTypeToAdd?.searchValue) {
            trackEvent("Adding New Business Type", { name: businessTypeToAdd?.searchValue });
            setCustomBusinessTypeName(businessTypeToAdd?.searchValue);
            setIsPromptDialogOpen(true);
        } else {
            trackEvent("Select Business Type", newValue);
            setResultTypes(newValue);
        }
    }

    const options = useMemo(() => {
        if (!businessTypes.isFetched) {
            return [];
        }
        const result: IBusinessTypeSimplifiedWithSearch[] = businessTypes.data || [];

        const name = searchValue;
        if (searchValue?.length > 1 && result.every(x => x.name !== searchValue && x.name !== name)) {
            result.push({ name, id: 'new', fieldsSet: BusinessTypesFieldsSet.UnknownType, searchValue });
        }
        return result;
    }, [businessTypes, searchValue])

    return (
        <>
            <Autocomplete
                {...field}
                multiple
                id="business-type-search-field"
                options={options}
                getOptionLabel={(option) => (option as IAutocompleteOption).name}
                filterOptions={(x) => x}
                onChange={selectValue}
                onInputChange={(_, value) => queryAutocompleteDebounced(value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={businessTypes.isFetching}
                loadingText="טוען..."
                noOptionsText={"התחלו להקליד כדי לחפש"}
                renderOption={(props, option) => {
                    let element;
                    if (option.id === 'new') {
                        element = <Button variant="outlined" startIcon={<Add />}>להוסיף {option.name}</Button>
                    } else {
                        element = <>{option.name}</>;
                    }
                    return (<OptionContainer {...props} key={option.name}>{element}</OptionContainer>);
                }
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                        }}
                        error={businessTypes.isError}
                        label={"סוגי עסק* (אפשר לבחור כמה)"}
                        helperText={businessTypes.isError ? 'משהו השתבש :(' : '' || "למשל: אינסטלטור, מספרה, וטרינר"}
                    />
                )}
            />

            <BusinessTypeAddPromptDialog
                isOpened={isPromptDialogOpen}
                confirm={handleAddBusinessType}
                cancel={closePromptDialog}
                businessTypeName={customBusinessTypeName}
            />
            <BusinessTypeAddedDialog
                businessTypeName={customBusinessTypeName}
                open={isAddBusinessTypeDialogOpen}
                handleClose={closeAddBusinessTypeDialog}
            />
        </>
    )
}
