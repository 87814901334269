import { CircularProgress } from "@mui/material";
import styled from "@emotion/styled";
import { IRecommendationsStatsItem, StatsLevel } from "../api/axios-client";
import { api } from "../api";
import { ITreeButtonProps } from "./TreeButton";
import { BusinessTypeLeave } from "./BusinessTypeLeave";
import { ChildrenContainer, SemanticButton } from "./SharedSemanticComponents";

const Container = styled.div`

`;
const BusinessTypesContainer = styled(ChildrenContainer)`
  margin: .75rem 1rem;
  padding-left: 1.5rem;
  display: grid;
  justify-content: flex-start;
  gap: .15rem;
`;


type ICategoryTreeProps = {
    category: IRecommendationsStatsItem;
    setOpen: (name: string) => void;
} & ITreeButtonProps;

export function CategoryTree({ category, isOpen, setOpen }: ICategoryTreeProps) {
    const onClick = () => setOpen(category.name);

    const label = `${category.name}\u00A0(${category.businesses})`;

    const businessTypes = api.Query.useGetRecommendationsStatsQuery(
        StatsLevel.Category,
        category.name,
        { enabled: isOpen }
    );

    return (
        <Container>
            <SemanticButton {...{isOpen, onClick, label}} variant="text" />
            {isOpen && businessTypes.isFetched && businessTypes.data &&
                <BusinessTypesContainer>
                    {businessTypes.data.items.map(businessType => <BusinessTypeLeave {...{
                        businessType,
                        isOpen,
                        key: businessType.name
                    }}>
                        {businessType.name} - {businessType.businesses}
                    </BusinessTypeLeave>)}
                </BusinessTypesContainer>
            }
            {businessTypes.isFetching && <CircularProgress />}
        </Container>
    )
}