import { FormProvider, useForm } from "react-hook-form";
import styled from "@emotion/styled";
import { TextInput } from "../shared/validated-form-inputs/TextInput";
import { ToggleButtonInput } from "../shared/validated-form-inputs/ToggleButtonInput";
import { ISignupUser } from "./user.schema";
import { signup } from "./user.service";
import { useEffect } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useNavigateOnLogin } from "../router/AnyUserRoute";
import { SingleCitySearch } from "../shared/components/SingleCitySearch";
import { ActionButtonOnBackground } from "../shared/components/styled-components/Buttons";
import { Check } from "@mui/icons-material";
import { trackEvent } from '../analytics';


const Container = styled.div`
  width: 100%;
  margin-top: 0.5rem;
`;
const Form = styled.form`
  display: grid;
  grid-gap: 2rem;
`;

export function SignupPage() {
    useEffect(() => {checkIfSignedUp()});

    const appInsights = useAppInsightsContext();

    const methods = useForm<ISignupUser>();
    const { handleSubmit, control } = methods;
    const navigate = useNavigateOnLogin();

    async function checkIfSignedUp() {
        const response = await fetch('/api/user/me');
        if (response.ok) {
            console.log('already signed up');
            navigate('/');
        }
    }

    const genderOptions = [
        {
            value: 'm',
            label: 'ממליץ',
        },
        {
            value: 'f',
            label: 'ממליצה',
        }
    ];

    const onSubmit = async (data: ISignupUser) => {


        const user = await signup(data);
        if (user) {
            trackEvent("User Signed Up", {});
            navigate('/welcome');
        } else {
            trackEvent("Sign Up Failed", {});
        }
    }

    return (
        <Container>
            <FormProvider {...methods}>
            <Form id="signup-form" onSubmit={handleSubmit(onSubmit)}>
                <TextInput name="firstName"
                           label="שם פרטי"
                           autocomplete="given-name"
                           control={control as any}
                           rules={{ minLength: 2, maxLength: 30, required: true }} // TODO: add error messages
                />
                <TextInput name="lastName"
                           label="שם משפחה"
                           autocomplete="family-name"
                           control={control as any}
                           rules={{ minLength: 2, maxLength: 30, required: true }} // TODO: add error messages
                />
                <SingleCitySearch
                    name="address"
                    label="עיר מגורים"
                    control={control as any}
                />
                <ToggleButtonInput
                    name="gender"
                    label="ממליץ או ממליצה?"
                    control={control as any} // TODO: understand why generics are not fine with control
                    options={genderOptions}
                    rules={{ required: 'אנא בחרו' }}
                />
                <ActionButtonOnBackground type="submit"
                                          variant="contained"
                                          size="medium"
                                          color="success"
                                          startIcon={<Check />}>
                    הרשמה
                </ActionButtonOnBackground>
            </Form>
            </FormProvider>
        </Container>
    )
}