import { IControlledFieldProps } from "./types";
import { TextAreaInput } from "../../../shared/validated-form-inputs/TextAreaInput";

export function ReviewField({ control }: IControlledFieldProps) {

    return (
        <TextAreaInput
            name="review"
            label="תארו את החוויה"
            control={control}
            rules={{ maxLength: { value: 1000, message: "יצא קצת ארוך מדי" } }}
        />
    );
}