import { api } from "../../../api";
import { AutoCompleteField } from "../../fields/AutoCompleteField";
import { Apartment } from "@mui/icons-material";
import { useSearchGeoItems } from "../../hooks/geography";
import { geoItemToString } from "../../utils/geoUtils";

export interface IGeoItemsFieldProps {
    label: string,
    onChange: (data: api.GeoItems[]) => void,
    value: api.GeoItems[],
    geoItemKinds?: api.GeoItemKind[]
}


export function GeoItemsField({geoItemKinds, ...props}: IGeoItemsFieldProps) {

    return (
        <AutoCompleteField
            {...props}
            name="serviceAreaCity"
            optionCategory={geoItemKinds || Object.values(api.GeoItemKind)}
            useOptions={useSearchGeoItems} 
            optionToString={geoItemToString}
            multiple
            icon={<Apartment />}
        />
    );
}