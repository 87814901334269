import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { api, queryClient } from "../api";
import { AddGroupMemberPhoneForm } from "./AddGroupMemberPhoneForm";
import { useContext, useMemo, useState } from "react";
import Fuse from 'fuse.js'
import { GroupMemberCard } from "./GroupMemberCard";
import { UserContext } from "../user/user.service";
import { orderBy } from "lodash";
import { AddUsersToGroupBody, GroupRole, IGroupMember, RemoveGroupMemberBody } from "../api/axios-client";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  justify-content: flex-start;
  @media (min-width: 768px) {
    margin-top: 1rem;
  }
`;

const PageHeader = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
`;
const SectionHeader = styled.h2`
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  font-size: 1.5rem;
`;

export function GroupViewPage() {

    const { groupIdentifier } = useParams<{ groupIdentifier: string }>();
    const userContext = useContext(UserContext);
    const myRoles = userContext.user.groups.find(g => g.identifier === groupIdentifier)?.roles || [];
    const iAmAdmin = myRoles.includes(GroupRole.Admin) || myRoles.includes(GroupRole.Owner);

    const group = api.Query.useGetGroupWithUsersQuery(groupIdentifier!, { refetchOnWindowFocus: false });
    const groupId = group.data?.id;

    const mutationOptions = {
        onSuccess: () => queryClient.refetchQueries(api.Query.getGroupWithUsersQueryKey(groupIdentifier!))
    };
    const addMember = api.Query.useAddUsersToGroupMutation(mutationOptions);
    const removeMember = api.Query.useRemoveGroupMemberMutation(mutationOptions);
    const setRoles = api.Query.useSetGroupMemberRolesMutation(mutationOptions)

    const [search, setSearch] = useState('');

    const [snackbarOpen, setSnackbarOpen] = useState(false);


    const fuse = useMemo(() => new Fuse(group?.data?.members || [], {
        keys: ['user.firstName', 'user.lastName', 'user.mobilePhone'], threshold: 0.3
    }), [group?.data]);

    if (group.data && groupId) {
        const addUser = (phone: string) => {
            addMember.mutate(new AddUsersToGroupBody({ groupId, userPhoneNumbers: [phone] }));
            setSnackbarOpen(true);
        }

        const SnackMessage = () => {
            const message = addMember.isSuccess
                ? <Alert severity="success" variant="filled" sx={{ width: '100%' }}>ההזמנה צלחה 🎉</Alert>
                : <Alert severity="error" variant="filled" sx={{ width: '100%' }}>ההזמנה נכשלה 😢</Alert>
            return (
                <Snackbar open={snackbarOpen && !addMember.isLoading}
                          autoHideDuration={6000}
                          onClose={() => setSnackbarOpen(false)}
                          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                    {message}
                </Snackbar>
            );
        }

        const members = !search
            ? orderBy(group.data.members, [
                x => x.user.id === userContext.user.id ? 0 : 1,
                x => x.roles.length > 0 ? 0 : 1,
                x => x.user.isShadow ? 1 : 0,
                x => x.user.firstName ? 0 : 1,
                x => x.user.lastName ? 0 : 1,
                x => x.user.mobilePhone,
            ])
            : fuse.search(search).map(r => r.item);

        const MembersList = ({ filteredMembers }: { filteredMembers: IGroupMember[] }) => (
            <>
                {filteredMembers.map(member => (
                    <GroupMemberCard
                        key={member.user.id}
                        member={member}
                        isMe={member.user.id === userContext.user.id}
                        allowRemove={iAmAdmin && !member.roles.includes(GroupRole.Owner)}
                        onRemove={userId => removeMember.mutate(new RemoveGroupMemberBody({ groupId, userId }))}
                        allowChangeRole={myRoles.includes(GroupRole.Owner) && !member.roles.includes(GroupRole.Owner)}
                        onSetRoles={(userId, roles) => setRoles.mutate(new api.SetGroupMemberRolesBody({
                            groupId,
                            userId,
                            roles
                        }))}
                    />))}
            </>
        );

        const ActiveMembersList = () => <MembersList filteredMembers={members.filter(m => !m.user.isShadow)} />
        const InactiveMembersList = () => {
            const inactiveMembers = members.filter(m => m.user.isShadow);
            if (inactiveMembers.length) {
                return (
                    <>
                        <SectionHeader>הוזמנו, אך לא נרשמו</SectionHeader>
                        <MembersList filteredMembers={inactiveMembers} />
                    </>)
            } else {
                return null;
            }
        }

        return (
            <Container>
                <PageHeader>{group.data.name}</PageHeader>
                <AddGroupMemberPhoneForm
                    search={search}
                    setSearch={setSearch}
                    onAdd={addUser}
                />
                <ActiveMembersList />
                {iAmAdmin && <InactiveMembersList />}
                <SnackMessage />
            </Container>
        )
    } else if (group.isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    } else {
        return (
            <Container>
                
            </Container>
        )
    }
}