import { IBusinessWithRecommendations } from "../../../api/axios-client";
import { Link } from "@mui/material";
import { Language } from "@mui/icons-material";
import { IconAndTextInlineContainer } from "../../../shared/components/styled-components/Containers";


const Container = IconAndTextInlineContainer;

export function ExternalLinkDisplayField({ business }: { business: IBusinessWithRecommendations }) {
    const { externalLink } = business;
    if (!externalLink) return null;

    const isoLinkString = (() => {
        if (externalLink.startsWith('http://') || externalLink.startsWith('https://')) {
            return externalLink;
        } else {
            return `https://${externalLink}`;
        }
    })();
    const humanizedLinkString = (() => {
        return isoLinkString.split('//')[1];
    })();

    return (
        <Container>
            <Language />
            <Link href={isoLinkString} target="_blank">{humanizedLinkString}</Link>
        </Container>
    )
}