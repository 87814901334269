import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SearchIcon from "../../assets/SearchIcon";
import { AddBusiness } from "@mui/icons-material";
import styled from "@emotion/styled";
import { useSearchParamsState } from "../../shared/hooks/useSearchParamsState";
import { StringParam } from "use-query-params";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ActionButtonOnBackground } from "../../shared/components/styled-components/Buttons";


const DialogTextContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
`;
const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
type IDialogProps = { isOpen: boolean, closeDialog: () => void };

export function ThankYouDialog({ isOpen, closeDialog }: IDialogProps) {
    const navigate = useNavigate();
    const [redirectUrl] = useSearchParamsState('redirect-url', StringParam);

    const navigateToHomePage = () => {
        navigate('/');
        closeDialog();
    }
    const navigateToAddRecommendation = () => {
        navigate('/recommend');
        closeDialog();
    }

    useEffect(() => {
        if (isOpen && redirectUrl) {
            setTimeout(() => {
                navigate(redirectUrl);
                closeDialog();
            }, 2000);
        }
    }, [redirectUrl, isOpen, closeDialog, navigate]);

    const dialogContentWithoutRedirectUrl = (
        <>
            <DialogContent>
              <DialogTextContainer>
                  <div>
                    אנו שמחים שהצטרפתם :)
                  </div>
                  <div>
                      לאן נמשיך מכאן?
                  </div>
              </DialogTextContainer>
            </DialogContent>
            <DialogActions>
              <ActionButtonOnBackground onClick={navigateToHomePage} variant="contained" size="medium" color="success" startIcon={<SearchIcon />}>
                  למצוא המלצה
              </ActionButtonOnBackground>
              <ActionButtonOnBackground onClick={navigateToAddRecommendation} variant="outlined" size="medium" startIcon={<AddBusiness />}>
                להוסיף המלצה
              </ActionButtonOnBackground>
            </DialogActions>
        </>
    );

    const dialogContentWithRedirectUrl = (
        <DialogContent>
            <Centered>
                <CircularProgress />
            </Centered>
        </DialogContent>

    )
    return (
        <Dialog
            open={isOpen}
            onClose={navigateToHomePage}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
               תודה רבה רבה על הנכונות לעזור!
            </DialogTitle>
            {redirectUrl ? dialogContentWithRedirectUrl : dialogContentWithoutRedirectUrl}
        </Dialog>
    )
}