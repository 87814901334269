import { api } from "../api";
import styled from "@emotion/styled";
import { Dialog, DialogActions, DialogTitle, FormControlLabel, Switch } from "@mui/material";
import { ApartmentOutlined, Block, PersonRemove, Phone } from "@mui/icons-material";
import { humanizePhone } from "../shared/utils/utils";
import { CardContainer } from "../shared/components/styled-components/Containers";
import { ActionButton } from "../shared/components/styled-components/Buttons";
import { useState } from "react";
import { GroupRole, IGroupMember } from "../api/axios-client";
import { ContactByPhoneDialog } from "../shared/components/display-fields/ContactByPhoneDialog";
import { trackEvent } from "../analytics";
import { useParams } from "react-router-dom";

const Container = styled(CardContainer)<{ isShadow: boolean }>`
  font-size: medium;
  display: flex;
  justify-content: space-between;
  color: ${props => props.isShadow ? '#060c3487' : props.theme.textColor};
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;
const DetailContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const UserName = styled.h3`
  margin: 0;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export interface IGroupMemberCardProps {
    member: IGroupMember;
    onRemove: (userId: string) => void;
    onSetRoles: (userId: string, roles: GroupRole[]) => void;
    allowRemove: boolean;
    allowChangeRole: boolean;
    isMe: boolean;
}

export function GroupMemberCard({
    allowRemove,
    allowChangeRole,
    isMe,
    member,
    onRemove,
    onSetRoles
}: IGroupMemberCardProps) {
    const { groupIdentifier } = useParams<{ groupIdentifier: string }>();
    const [removeUserDialogOpen, setRemoveUserDialogOpen] = useState(false);
    const [contactByPhoneDialogOpen, setContactByPhoneDialogOpen] = useState(false);

    const fullName = `${member.user.firstName} ${member.user.lastName}`;

    const analyticParams = { groupIdentifier, userId: member.user.id, userName: fullName };
    const whatsAppAnalyticFunc = async () => trackEvent("WhatsApp Group Member", analyticParams);
    const dialAnalyticFunc = async () => trackEvent("Dial Group Member", analyticParams);

    const removeUser = () => onRemove(member.user.id);
    return (
        <Container key={member.user.id} isShadow={member.user.isShadow}>
            <DetailsContainer>
                <UserName>{fullName} {isMe && ' (אני)'}</UserName>
                {member.user.isShadow && <DetailContainer><Block />לא נרשמ/ה</DetailContainer>}
                {member.user.addressInfo?.city &&
                    <DetailContainer><ApartmentOutlined />{member.user.addressInfo.city}</DetailContainer>}
                <DetailContainer><Phone />{humanizePhone(member.user.mobilePhone)}</DetailContainer>
            </DetailsContainer>
            <ActionsContainer>

                {!isMe &&
                    <ActionButton variant="outlined"
                                  onClick={() => setContactByPhoneDialogOpen(true)}
                                  startIcon={<Phone />}>
                        לצור קשר
                    </ActionButton>
                }
                {allowRemove && !isMe &&
                    <ActionButton
                        variant="outlined"
                        onClick={() => setRemoveUserDialogOpen(true)}
                        startIcon={<PersonRemove />}>
                        להסיר
                    </ActionButton>
                }
                <ButtonContainer>
                    {allowChangeRole && !isMe && !member.user.isShadow &&
                        <FormControlLabel
                            control={<Switch
                                defaultChecked={member.roles.includes(api.GroupRole.Admin)}
                                onChange={e => onSetRoles(
                                    member.user.id,
                                    e.target.checked ? [api.GroupRole.Admin] : []
                                )}
                            />}
                            label="מנהל"
                            labelPlacement="start" />}
                </ButtonContainer>
            </ActionsContainer>
            <Dialog open={removeUserDialogOpen} onClose={() => setRemoveUserDialogOpen(false)}>
                <DialogTitle>להסיר {fullName} מהקבוצה?</DialogTitle>
                <DialogActions>
                    <ActionButton variant="contained" color="warning" onClick={removeUser}>להסיר</ActionButton>
                    <ActionButton variant="outlined" onClick={() => setRemoveUserDialogOpen(false)}>ביטול</ActionButton>
                </DialogActions>
            </Dialog>
            <ContactByPhoneDialog {...{ whatsAppAnalyticFunc, dialAnalyticFunc }}
                                  name={fullName}
                                  phone={member.user.mobilePhone}
                                  isOpened={contactByPhoneDialogOpen}
                                  setIsOpened={setContactByPhoneDialogOpen} />
         </Container>
    );
}