import styled from "@emotion/styled";
import { BusinessPhoneForm } from "./forms/BusinessPhoneForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { internationalizePhone } from "../../shared/utils/utils";


const Container = styled.div`
  display: grid;
  width: 100%;
`;

export function RecommendGetPhone() {
    const navigate = useNavigate();
    const [query, _] = useSearchParams();
    const redirectToNextStage = (phone: string, name?: string) => {
        const state: Record<string, string | null> = {};
        state.name = name || null;
        state.businessType = query.get('businessType') || null;
        navigate(`/recommend/${internationalizePhone(phone)}`,{ state });
    }



    return (
        <Container>
            <BusinessPhoneForm setBusinessPhone={redirectToNextStage} />
        </Container>
    )
}