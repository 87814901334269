// @ts-nocheck

import { createTheme } from "@mui/material/styles";

export const customTheme = {
    white: '#F3F5F7',
    bgColor: '#C8F2FF',
    redMarker: '#FF7254',
    yellowMarker: '#FFFF33',
    greenMarker: '#77E14B',
    textColor: '#060C34',
    callButton: '#8EE53F',
    errorColor: '#FF7254',

    shadow: '20px 40px 60px rgba(62, 68, 128, 0.25)',
    blockBorderRadius: '16px',
    inputBorderRadius: '9px',
    smallUserpicBorderWidth: 3, //px
    typography: {
        fontFamily: 'Fredoka',
    },
    palette: {
        primary: {
            main: '#060C34',
        },
        success: {
            main: '#9fec80',
        },
        warning: {
            main: '#FF7254',
        },
        error: {
            main: '#d32f2f',
        },
        whatsapp: {
            main: '#25d366'
        },
        waze: {
            main: '#87e1ff',
        }
    },
    direction: 'rtl',
}

export const theme = createTheme(customTheme)