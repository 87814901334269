import React, { Suspense } from 'react';
import styled from "@emotion/styled";
import { CacheProvider, css, Global } from "@emotion/react";
import { ThemeProvider } from '@mui/material/styles';
import { RouterProvider } from "react-router-dom";
import { theme } from "./shared/theme";
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { CircularProgress, StyledEngineProvider } from "@mui/material";
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { MainRouter } from "./router/MainRouter";
import { emptyUser, UserContext } from "./user/user.service";
import { IUserContext } from "./user/user.schema";
import { appInsights, reactPlugin } from "./analytics";
import { queryClient } from './api';
import { QueryClientProvider } from '@tanstack/react-query';

const Container = styled.div`
  height: 100vh;

  background: ${p => p.theme.bgColor};
  color: ${p => p.theme.textColor};
`;

const Centered = styled.div`
  display: grid;
    justify-content: center;
  height: calc(100% - 8rem - 30px); // 6rem is the height of the header, 30px is header margin
`;

const AppContent = styled.div`
  position: relative;
  display: grid;
  
  max-width: 800px;
  min-width: 20vw;
  width: calc(100vw - 2rem);
  justify-self: center;
  align-items: center;

  font-size: 1.6rem;
  padding: 1rem;
  @media (min-width: 815px) {
    padding: 0 5rem 5rem 5rem;
    width: calc(100% - 2rem); // account for padding
    
  }
  
  overflow: auto;
`;

const LogoContainer = styled.div`
  font-size: 5rem;
  display: grid;
  justify-content: center;
  height: 10rem;
  box-shadow: 0 5px 15px #99bbc378;
`;

const Header = styled.h1`
  font-size: 5rem;
  margin-top: 20px;
  margin-bottom: 0;
  
  cursor: pointer;
`;
const SubHeader = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
`;

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});


const globalStyles = css`
  @import url("https://fonts.googleapis.com/css?family=Fredoka:400,500,600,700&display=swap");

  body {
    font-family: Fredoka, sans-serif;
  }

  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  label.MuiFormControlLabel-root {
    margin-right: 0;
  }
`;

// appInsights.loadAppInsights();
// appInsights.trackPageView();

const navigateHome = () => window.location.href = "/";
function App() {
    const [userContext, setUserContext] = React.useState<IUserContext>({ user: emptyUser, hasBusiness: false });

    const Router = MainRouter({ user: userContext.user, setUser: setUserContext });


    return (
        <QueryClientProvider client={queryClient}>
            <Suspense fallback={<CircularProgress />}>
                {/*<AppInsightsContext.Provider value={reactPlugin}>*/}
                {/*    <AppInsightsErrorBoundary onError={() => <h1>I believe something went wrong</h1>} appInsights={reactPlugin}>*/}
                        <StyledEngineProvider injectFirst>
                            <CacheProvider value={cacheRtl}>
                                <ThemeProvider theme={theme}>
                                    <Global styles={globalStyles} />
                                    <UserContext.Provider value={userContext}>
                                        <Container>
                                            <LogoContainer>
                                                <Header onClick={navigateHome}>
                                                    מומלץ.נט
                                                </Header>
                                                <SubHeader onClick={navigateHome} role="doc-subtitle">
                                                    קהילה סגורה של אנשים אמינים
                                                </SubHeader>
                                            </LogoContainer>
                                            <Centered>
                                                <AppContent>
                                                    <RouterProvider router={Router} />
                                                </AppContent>
                                            </Centered>
                                        </Container>
                                    </UserContext.Provider>
                                </ThemeProvider>
                            </CacheProvider>
                        </StyledEngineProvider>
                    {/*</AppInsightsErrorBoundary>*/}
                {/*</AppInsightsContext.Provider>*/}
            </Suspense>
        </QueryClientProvider>
    );
}

export default App;
