import { api } from "../../api";
import { BusinessTypesFieldsSet, GeoItemKind } from "../../api/axios-client";
import { fieldTypes, getFieldSet, IFieldType } from "../../business/business-fields/fieldsOfBusinessTypes";

export const geoItemToString = (option: api.GeoItems) => {
    return option.kind !== GeoItemKind.GeneratedArea
        ? option.value : `${option.value} והסביבה`;
}


const allFields = Object.keys(fieldTypes) as IFieldType[];
const addressFields = allFields.filter(f => fieldTypes[f].name === 'addressInfo');
const serviceAreaFields = allFields.filter(f => fieldTypes[f].name === 'serviceArea');
export const locationFields: IFieldType[] = [...addressFields, ...serviceAreaFields];

const locationChoiceFieldsContent = {
    clubActivityAt: ['addressInfo'],
    serviceProvidedAt: ['serviceArea', 'optionalServiceArea', 'addressInfo', 'optionalAddressInfo'],
    serviceProvidedAtOffline: ['serviceArea', 'optionalServiceArea', 'addressInfo', 'optionalAddressInfo'],
} satisfies Partial<Record<IFieldType, IFieldType[]>>;
export type ILocationChoiceField = keyof typeof locationChoiceFieldsContent;
const locationChoiceFields = Object.keys(locationChoiceFieldsContent);


export function fieldSetsLocationChoice(fieldSetNames: BusinessTypesFieldsSet[]): ILocationChoiceField | null {
    return getFieldSet(fieldSetNames)
            .find(f => locationChoiceFields.includes(f)) as ILocationChoiceField
        ?? null;
}

export function isFieldChoosableLocation(locationChoice: ILocationChoiceField, fieldName: IFieldType) {
    return (locationChoiceFieldsContent[locationChoice] as IFieldType[]).includes(fieldName);
}