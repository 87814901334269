import { IFieldType } from "../fieldsOfBusinessTypes";
import { AgeStagesField } from "./AgeStagesField";
import { Control } from "react-hook-form";
import { ServiceProvidedAtField } from "./ServiceProvidedAtField";
import { NameField } from "./NameField";
import { PhoneField } from "./PhoneField";
import { ExternalLinkField } from "./ExternalLinkField";
import { EducationStagesField } from "./EducationStagesField";
import { ClubActivityAtField } from "./ClubActivityAtField";
import { ServiceAreaCityField } from "./ServiceAreaCityField";
import { AddressInfoField } from "./AddressInfoField";
import { GeoItemsSearch } from "../../../shared/components/GeoItemsSearch";
import { IFieldParams } from "./types";


type FieldFactoryProps = { fieldName: IFieldType, control: Control, fieldParams?: IFieldParams}
export function FieldFactory({ fieldName, control, fieldParams }: FieldFactoryProps): JSX.Element {
    const fieldsMap: Record<IFieldType, JSX.Element> = {
         'ageStages':  <AgeStagesField {...{control}} />,
         'serviceArea': <ServiceAreaCityField {...{ control }} />,
         'serviceAreaCity': <ServiceAreaCityField {...{ control }} />,
         'optionalServiceArea': <ServiceAreaCityField {...{ control, isOptional: true }} />,
         'serviceProvidedAtOffline':  <ServiceProvidedAtField {...{control, fieldParams}} />,
         'serviceProvidedAt':  <ServiceProvidedAtField {...{control, fieldParams}} medium="onlineOffline" />,
         'name':  <NameField control={control} label="שם" />,
         'businessName':  <NameField control={control} label="שם העסק" />,
         'placeName':  <NameField control={control} label="שם המקום" />,
         'hobbyClubName':  <NameField control={control} label="שם החוג" />,
         'phone':  <PhoneField {...{ control }} />,
         'externalLink':  <ExternalLinkField {...{ control }} />,
         'addressInfo':  <AddressInfoField {...{ control }} />,
         'optionalAddressInfo':  <AddressInfoField {...{ control, isOptional: true }} />,
         'educationStages':  <EducationStagesField {...{ control, untilUniversity: true }} />,
         'kidsEducationStages':  <EducationStagesField {...{ control }} />,
         'clubActivityAt':  <ClubActivityAtField {...{control, fieldParams}} />,
         'fullServiceArea': <GeoItemsSearch {...{ control }} />
    }

    return fieldsMap[fieldName];
}
