import { IControlledFieldProps } from "./types";
import { useWatch } from "react-hook-form";
import { ServiceAreaCityField } from "./ServiceAreaCityField";
import { AddressInfoField } from "./AddressInfoField";
import { GeoItemsSearch } from "../../../shared/components/GeoItemsSearch";
import { ServiceProvidedAt } from "../../../api/axios-client";

type ILocationOfServiceProvidedAtFieldProps = {
    locationChoices: ServiceProvidedAt[]
} & IControlledFieldProps;
export function LocationOfServiceProvidedAtField({
    locationChoices,
    control,
    fieldParams: { allowEditAddress = true, allowMultipleServiceAreas = false } = {}

}: ILocationOfServiceProvidedAtFieldProps) {
    const name = "serviceProvidedAt";
    const serviceProvidedAt: ServiceProvidedAt[] = useWatch({ control, name });

    const showAddress = locationChoices.includes(ServiceProvidedAt.BusinessPremises)
        && serviceProvidedAt?.includes(ServiceProvidedAt.BusinessPremises)
        && allowEditAddress;
    const showServiceArea = locationChoices.includes(ServiceProvidedAt.CustomerPremises)
        && serviceProvidedAt?.includes(ServiceProvidedAt.CustomerPremises);

    return (
        serviceProvidedAt &&
        <>
            {showAddress
                && <AddressInfoField control={control} />}
            {showServiceArea
                && (allowMultipleServiceAreas
                        ? <GeoItemsSearch control={control} label="אזורי שרות (ניתן לבחור כמה)" name="serviceArea" />
                        : <ServiceAreaCityField control={control} />
                )
            }
        </>
    )
}