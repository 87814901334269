import { GeoItems } from "../../api/axios-client";
import { api } from "../../api";

export function fixFormValues(values: Record<string, any>) {
    values = sanitizeFormValues(values);
    values = fixFormValuesGeo(values);
    return values;
}
export function sanitizeFormValues(values: Record<string, any>) {
    for (const key in values) {
        if (values[key] === '') {
            delete values[key];
        }
    }
    return values;
}

export function fixFormValuesGeo(values: Record<string, any>) {
    if (values?.serviceAreaCity) {
        values.serviceArea = [{
            value: values?.serviceAreaCity,
            kind: 'city',
        }];
        delete values?.serviceAreaCity;
    }

    values.serviceArea = values.serviceArea ? values.serviceArea.map((sa: GeoItems) => new api.GeoItems(sa)) : [];
    values.addressInfo = new api.AddressInfo(values.addressInfo || {});

    return values;
}