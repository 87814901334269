import styled from "@emotion/styled";
import { PersonAdd } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { internationalizePhone, isPhoneMobile } from "../shared/utils/utils";
import { ActionButton } from "../shared/components/styled-components/Buttons";
import { useState } from "react";
import { ContactPicker } from "../shared/components/contact-picker/ContactPicker";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const DialogActionContent = styled(DialogContent)`
  display: grid;
  gap: 1rem;
`;

export interface IAddGroupMemberPhoneFormProps {
    search: string;
    setSearch: (value: string) => void;
    onAdd: (phone: string) => void;
}

export function AddGroupMemberPhoneForm({ onAdd, search, setSearch }: IAddGroupMemberPhoneFormProps) {
    const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

    const handlePickedContact = (contacts: IContact[]) => {
        const contact = contacts[0];
        const phone = contact.tel.find(tel => isPhoneMobile(tel));
        if (phone) {
            setSearch(phone);
        }
    }

    const openInviteDialog = () => {
        setSearch('');
        setInviteDialogOpen(true);
    }
    const inviteUser = () => {
        onAdd(internationalizePhone(search));
        setSearch('');
        setInviteDialogOpen(false);
    }

    return (
        <Container>
            <ActionButton
                variant="contained"
                color="success"
                onClick={openInviteDialog}
                startIcon={<PersonAdd />}>
                להזמין לקבוצה
            </ActionButton>
            <TextField
                name="search"
                label="חיפוש לפי שם או טלפון"
                value={search}
                onChange={data => setSearch(data.target.value)}
            />

            <Dialog open={inviteDialogOpen} onClose={() => setInviteDialogOpen(false)}>
                <DialogTitle>
                    להזמין חבר/ה חדש/ה לקבוצה
                </DialogTitle>
                <DialogActionContent>
                    <TextField
                        name="phone"
                        label="מספר טלפון נייד"
                        value={search}
                        onChange={data => setSearch(data.target.value)}
                    />
                    <ContactPicker handleContacts={handlePickedContact} />
                    <ActionButton variant="contained"
                                  color="success"
                                  onClick={inviteUser}
                                  disabled={!isPhoneMobile(search)}
                                  startIcon={<PersonAdd />}>
                        להזמין
                    </ActionButton>
                </DialogActionContent>
            </Dialog>
        </Container>
    )
}