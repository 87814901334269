import { FormProvider, useForm, useWatch } from "react-hook-form";
import React, { useEffect } from "react";
import { AddBusiness } from "@mui/icons-material";
import { Form } from "../../../shared/components/styled-components/Form";
import { ReviewField } from "../../../business/business-fields/form-fields/ReviewField";
import { useUserCity } from "../../../shared/hooks/geography";
import { ActionButton, ActionButtonOnBackground } from "../../../shared/components/styled-components/Buttons";
import { BusinessFieldsForm } from "../../../business/BusinessFieldsForm";
import { fixFormValues } from "../../../shared/utils/formUtils";
import { useAddRecommendationAndBusinessMutation, useSearchBusinessTypeQuery } from "../../../api/axios-client/Query";
import { trackEvent, trackException } from "../../../analytics";
import { IRecommendedBusinessFields, ISimplifiedBusinessType } from "../../../api/axios-client";
import { CircularProgress, TextField } from "@mui/material";
import { ErrorText } from "../../../shared/components/styled-components/Text";
import { api } from "../../../api";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { humanizePhone } from "../../../shared/utils/utils";
import { IFieldType } from "../../../business/business-fields/fieldsOfBusinessTypes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const PhoneContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
`;

type IRecommendationFormProps = { phoneNumber: string, businessType?: string };

export function NewBusinessRecommendationForm({ phoneNumber }: IRecommendationFormProps) {
    const userCity = useUserCity()?.value || '';
    const submitRecommendation = useAddRecommendationAndBusinessMutation();
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {trackEvent('Recommending New Business', { phoneNumber })});

    const businessTypesFieldName = 'businessTypes';


    let values = {
        addressInfo: { city: userCity },
        serviceAreaCity: userCity,
        name: state?.name || '',
        [businessTypesFieldName]: useSearchBusinessTypeQuery(
            { keyword: state?.businessType || '' },
            { enabled: !!state?.businessType }
        ).data?.filter((bt) => bt.name === state?.businessType) || []
    }

    const methods = useForm({ mode: 'onTouched', values, shouldUnregister: true });
    const { handleSubmit, control, formState, reset } = methods;

    const businessTypes = useWatch<any>({ control, name: businessTypesFieldName });

    const onSubmit = async (data: any) => {
        const {review, ...businessFields} = data;
        try {
            let business = fixFormValues(businessFields);
            business = {
                ...business,
                phoneNumber,
                businessTypeIds: business.businessTypes.map((bt: ISimplifiedBusinessType) => bt.id)
            };
            const businessObj = new api.RecommendedBusinessFields(business as IRecommendedBusinessFields);
            await submitRecommendation.mutateAsync(
                new api.AddRecommendationAndBusinessBody({ business: businessObj, text: review })
            );
            trackEvent(
                'Submit Recommendation For New Business',
                {
                    businessName: data.name,
                    businessTypes: data.businessTypes.map((bt: ISimplifiedBusinessType) => bt.name).join(', ')
                }
            );
            navigate('/recommend/success');
        } catch (e) {
            trackException('Failed to Submit Recommendation For New Business', e as Error);
        }
    };

    const navigateToEditPhone = () => navigate('/recommend');

    return (
        <FormProvider {...methods}>
            <Container>
            <PhoneContainer>
                <TextField label="מס׳ טלפון" disabled value={humanizePhone(phoneNumber)} />
                <ActionButton variant="outlined" onClick={navigateToEditPhone}>לשנות</ActionButton>
            </PhoneContainer>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <BusinessFieldsForm {...{ control }} />
                {businessTypes && <ReviewField {...{ control }} />}
                {submitRecommendation.isError && <ErrorText>משהו השתבש :(</ErrorText>}
                {submitRecommendation.isLoading && <CircularProgress />}
                {!submitRecommendation.isLoading &&
                    <ActionButtonOnBackground type="submit"
                                              variant="contained"
                                              size="large"
                                              color="success"
                                              disabled={!formState.isValid}
                                              endIcon={<AddBusiness />}>
                        שליחת המלצה
                    </ActionButtonOnBackground>
                }
            </Form>
            </Container>
        </FormProvider>
    );
}