import { TextInput } from "../../../shared/validated-form-inputs/TextInput";
import { IControlledFieldProps } from "./types";

export function ExternalLinkField({ control, isRequired }: IControlledFieldProps) {
    // this field is optional by default
    const required = isRequired ?? false;

    return (
        <TextInput
            name="externalLink"
            label="קישור לאתר"
            control={control}
            rules={{required: {value: required, message: 'שדה חובה'}, pattern: { value: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, message: 'קישור לא תקין' } }}
        />
    );
}