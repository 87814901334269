import { IBusinessWithRecommendations } from "../../../api/axios-client";
import styled from "@emotion/styled";
import { CommonCheckboxDisplay } from "./CommonCheckboxDisplay";
import { fieldTypes, getFieldSet, IFieldType } from "../fieldsOfBusinessTypes";
import { commonFields } from "../../business-card/businessCardHelpers";

const Container = styled.div`

`;

export function DisplayCommonFields({ business }: { business: IBusinessWithRecommendations }) {
    const fieldsToDisplay = commonFields(business);

    const fieldsSet = getFieldSet(business.businessTypes.map(bt => bt.fieldsSet));
    const filteredFields: Partial<Record<IFieldType, any>> = {};
    for (const fieldName in fieldsToDisplay) {
        if (fieldsSet.includes(fieldName as IFieldType) || Object.values(fieldTypes).find(ft => ft.name === fieldName)) {
            filteredFields[fieldName as IFieldType] = fieldsToDisplay[fieldName as keyof typeof fieldsToDisplay];
        }
    }

    return (
        <Container>
            {
                Object.entries(filteredFields).map(([name, value]) => (
                    typeof value === 'string' || Array.isArray(value)
                    ? <CommonCheckboxDisplay {...{ name, value }} key={name} />
                    : null
                ))
            }

        </Container>
    )
}