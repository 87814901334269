import { GeoItemKind, IBusinessWithRecommendations, IGeoItems } from "../../../api/axios-client";
import { geoItemToString } from "../../../shared/utils/geoUtils";
import styled from "@emotion/styled";

const Container = styled.div`
  font-size: 1rem;
`;

export function ServiceAreaDisplayField({ business }: { business: IBusinessWithRecommendations }) {
    const { serviceArea } = business;
    if (!serviceArea || !serviceArea.length) return null;

    const serviceAreaString = (() => {
        let str = 'נגיע עד עליך ';
        // remove all cities that also has generated areas
        const filteredAreas = serviceArea.filter(sa => {
            if (sa.kind === GeoItemKind.City) {
                const hasArea = serviceArea.find(area => area.kind === GeoItemKind.GeneratedArea && sa.value === area.value);
                return !hasArea;
            }
            return true;
        });

        const serviceAreaStrings = filteredAreas.map(sa => geoItemToString(sa));
        if (serviceAreaStrings.length > 1) {
            str += 'באזורים: ';
        } else {
            str += 'ב';
        }
        str += serviceAreaStrings.splice(0, 10).join(', ');

        if (serviceAreaStrings.length > 10) {
            str += ' ועוד ';
            str += serviceAreaStrings.length - 10;
        }

        return str;
    })();
    return (
        <Container>
            {serviceAreaString}
        </Container>
    )
}