import { BusinessFieldsFactory } from "./business-fields/BusinessFieldsFactory";
import { Control, useFormContext } from "react-hook-form";
import styled from "@emotion/styled";
import { BusinessTypesSelectField } from "../shared/components/business-type-search-field/BusinessTypesSelectField";


const BusinessTypeContainer = styled.div`
  display: grid;
  gap: 0.5rem;
`;

type IBusinessFormProps = {
    control: Control<any>;
    isUpdatingBusiness?: boolean;
    businessTypesFieldName?: string;
}

export function BusinessFieldsForm({
    control,
    isUpdatingBusiness,
    businessTypesFieldName = 'businessTypes'
}: IBusinessFormProps) {
    const { getValues, watch } = useFormContext();

    const initialBusinessTypes = getValues(businessTypesFieldName);
    const businessTypes = watch(businessTypesFieldName) || initialBusinessTypes;

    return (
        <>
            <BusinessTypeContainer>
                <BusinessTypesSelectField {...{
                    control,
                    name: businessTypesFieldName
                }} />
            </BusinessTypeContainer>
            {businessTypes && businessTypes.length > 0 &&
                <BusinessFieldsFactory {...{ businessTypes, control, isUpdatingBusiness }} />
            }
        </>
    )
}