import styled from "@emotion/styled";
import { BusinessCard } from "../../../business/business-card/BusinessCard";
import { FormProvider, useForm } from "react-hook-form";
import { Form } from "../../../shared/components/styled-components/Form";
import { ReviewField } from "../../../business/business-fields/form-fields/ReviewField";
import { FieldFactory } from "../../../business/business-fields/form-fields/FieldFactory";
import { IAddressInfo, IBusinessWithRecommendations, ServiceProvidedAt } from "../../../api/axios-client";
import { useUserCity } from "../../../shared/hooks/geography";
import { ActionButtonOnBackground } from "../../../shared/components/styled-components/Buttons";
import {
    fieldTypes,
    getLocationNormalizedFieldSet,
    IFieldType
} from "../../../business/business-fields/fieldsOfBusinessTypes";
import { trackEvent, trackException } from "../../../analytics";
import { useAddRecommendationForBusinessMutation } from "../../../api/axios-client/Query";
import { api } from "../../../api";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Error } from "@mui/icons-material";
import { ErrorText } from "../../../shared/components/styled-components/Text";
import { fixFormValues } from "../../../shared/utils/formUtils";
import { useEffect } from "react";

const Container = styled.div`
  display: grid;
  grid-gap: 2rem;
  width: 100%;
`;

type IFormValues = { text: string, addressInfo?: IAddressInfo | null, serviceAreaCity?: string, serviceProvidedAt?: ServiceProvidedAt[] | null };

type ExistingBusinessRecommendationFormProps = {
    business: IBusinessWithRecommendations;
}

export function ExistingBusinessRecommendationForm({
    business,
}: ExistingBusinessRecommendationFormProps) {
    const userCity = useUserCity();
    const submitRecommendation = useAddRecommendationForBusinessMutation();
    const navigate = useNavigate();

    useEffect(() => {
        trackEvent(
            'Recommending Existing Business',
            { phoneNumber: business.phoneNumber }
        )
    });

    async function submit(formValues: Record<string, any>) {
        formValues = fixFormValues(formValues);
        const { review, ...businessFields } = formValues;
        const businessObj = new api.ExistingBusinessFields(businessFields);
        const payload = new api.AddRecommendationForBusinessBody({
            businessId: business.id, text: review, business: businessObj
        })

        try {
            await submitRecommendation.mutateAsync(payload);
            trackEvent('Submit Recommendation For Existing Business', { businessName: business.name });
            navigate('/recommend/success');
        } catch (e) {
            trackException('Failed to Submit Recommendation For Existing Business', e as Error);
        }
    }

    function getServiceProvidedAtFields(): IFieldType[] | null {
        const fieldSetNames = business.businessTypes.map(bt => bt.fieldsSet);
        const businessHasAddress = business.addressInfo && business.addressInfo.city && business.addressInfo.street;
        return getLocationNormalizedFieldSet(fieldSetNames).filter(f => (
            fieldTypes[f].name === 'serviceProvidedAt'
            || fieldTypes[f].name === 'serviceArea'
            || (!businessHasAddress && fieldTypes[f].name === 'addressInfo')
        )) || null;
    }

    const serviceProvidedAt = getServiceProvidedAtFields();

    const defaultValues = {
        addressInfo: business.addressInfo && Object.keys(business.addressInfo).length
            ? business.addressInfo
            : { city: userCity?.value },
        serviceAreaCity: userCity?.value
    }

    const methods = useForm<IFormValues>({
        mode: 'onTouched',
        defaultValues,
        shouldUnregister: true
    });

    const { handleSubmit, control } = methods;

    const allowEditAddress = !business.addressInfo?.city || !business.addressInfo?.street;

    return (
        <Container>
            <BusinessCard {...{ business }} />
            <FormProvider {...methods}>
                <Form id="choose-your-business-types" onSubmit={handleSubmit(submit)}>
                    {!!serviceProvidedAt?.length && serviceProvidedAt.map(spa => (
                        <FieldFactory fieldName={spa}
                                      control={control as any}
                                      fieldParams={{ allowEditAddress }}
                                      key={spa}
                        />
                    ))

                    }
                    <ReviewField control={control as any} />
                    {submitRecommendation.isError && <ErrorText>משהו השתבש, אנא נסו שנית</ErrorText>}
                    {submitRecommendation.isLoading
                        ? <CircularProgress />
                        : <ActionButtonOnBackground type="submit" variant="contained" size="large" color="success">
                                הוספת המלצה
                            </ActionButtonOnBackground>
                    }
                </Form>
            </FormProvider>
        </Container>
    )
}