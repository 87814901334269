import { IDisplayRecommendation } from "../api/axios-client";
import { CardContainer } from "../shared/components/styled-components/Containers";
import styled from "@emotion/styled";
import { WhatsApp } from "@mui/icons-material";
import { ActionButton } from "../shared/components/styled-components/Buttons";
import { trackEvent } from "../analytics";


const UserDetailsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const NameContainer = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
`;
const ContactButtonContainer = styled.div`
  
`;

const TextAndIconContainer = styled.div`
  margin-top: 1rem;
  display: grid;
  //grid-template-columns: 3.5rem 1fr;
`;
const IconContainer = styled.div`
  border: 2px solid ${p => p.theme.textColor};
  width: 2.5rem;
  height: 2.5rem;
    border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TextContainer = styled.div`
    font-size: 1rem;
  display: flex;
  align-items: center;
`;
const NoTextMessage = styled(TextContainer)`
  color: ${p => p.theme.textColor}50;
`;



type IRecommendationProps = { recommendation: IDisplayRecommendation; }

export function RecommendationCard({ recommendation }: IRecommendationProps) {
    const user = recommendation.user;
    const fullName = `${user.firstName} ${user.lastName}`;
    const noTextMessage = `המלצה ללא תגובה`;

    const analyticProperties = {
        askingUser: fullName,
    }
    function whatsappUser() {
        trackEvent("Whatsapp User", {
            properties: analyticProperties,
        });
        window.open(`https://wa.me/${user.mobilePhone}`);
    }

    return (
        <CardContainer>
            <UserDetailsContainer>
                <NameContainer>{fullName}</NameContainer>
                <ContactButtonContainer>
                    <ActionButton variant="outlined" onClick={whatsappUser} size="small" startIcon={<WhatsApp />}>לשאול בפרטי</ActionButton>
                </ContactButtonContainer>
            </UserDetailsContainer>
            <TextAndIconContainer>
                {/*<IconContainer><ThumbUp /></IconContainer>*/}
                {recommendation.text && <TextContainer>{recommendation.text}</TextContainer>}
                {!recommendation.text && <NoTextMessage>{noTextMessage}</NoTextMessage>}
            </TextAndIconContainer>

        </CardContainer>
    )
}