import { TextInput } from "../../../shared/validated-form-inputs/TextInput";
import { IControlledFieldProps } from "./types";
import { isPhoneValid } from "../../../shared/utils/utils";

export function PhoneField({ control, isRequired, name, label, helperText }: IControlledFieldProps) {
    // TODO: uderstand why tf it doesn't work with value object
    const required = isRequired ?? true;
    const validate = (value: string) => {
        // we check if mandatory in another validator
        if (!value) return true;

        return isPhoneValid(value) || "מספר טלפון לא תקין";
    }

    return (
        <TextInput
            name={name || "phone"}
            label={label || "מס׳ טלפון"}
            type="tel"
            control={control}
            rules={{ required:  "שדה חובה", validate }}
            helpText={helperText}
        />
    );
}