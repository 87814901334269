import { searchBusinessByPhone } from "../../business/business.service";
import { checkRecommendationExists } from "../recommendations.service";
import { useEffect, useState } from "react";
import { IBusinessWithRecommendations } from "../../api/axios-client";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { ExistingBusinessRecommendationForm } from "./forms/ExistingBusinessRecommendationForm";
import { NewBusinessRecommendationForm } from "./forms/NewBusinessRecommendationForm";
import { useNavigate, useParams } from "react-router-dom";
import { ActionButtonOnBackground } from "../../shared/components/styled-components/Buttons";

const Container = styled.div`

`;
const AlreadyRecommendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
const AlreadyRecommendedButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export function RecommendBusiness() {
    const { phoneNumber } = useParams();
    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
    const [existingBusiness, setExistingBusiness] = useState<IBusinessWithRecommendations | null>(null);
    const [error, setError] = useState<string | null>(null);

    const navigate = useNavigate();
    const navigateToRecommend = () => navigate('/recommend');
    const navigateHome = () => navigate('/');

    useEffect(
        () => {
            phoneNumber
                ? searchExistingBusiness(phoneNumber)
                : navigateToRecommend();
        },
        [phoneNumber]
    );

    if (!phoneNumber) return <></>

    const searchExistingBusiness = async (businessPhone: string) => {
        setIsPageLoading(true);
        const existingBusiness = await searchBusinessByPhone(businessPhone);
        if (existingBusiness) {
            if (await checkRecommendationExists(existingBusiness.id)) {
                setError('כבר המלצת בעבר על עסק זה :)');
                setIsPageLoading(false);
                return;
            }
            setExistingBusiness(existingBusiness);
        }

        setIsPageLoading(false);
    }

    const AlreadyRecommended = () => (
        <AlreadyRecommendedContainer>
            <div>{error}</div>
            <AlreadyRecommendedButtonsContainer>
                <ActionButtonOnBackground variant="contained" size="large" color="success" onClick={navigateToRecommend}>
                    להוסיף עוד עסק
                </ActionButtonOnBackground>
                <ActionButtonOnBackground variant="outlined" size="large" onClick={navigateHome}>
                    לעמוד הבית
                </ActionButtonOnBackground>
            </AlreadyRecommendedButtonsContainer>
        </AlreadyRecommendedContainer>
    )

    return (
        <Container>
            {error && <AlreadyRecommended />}
            {isPageLoading && <CircularProgress />}
            {!error && !isPageLoading &&
                (existingBusiness
                    ? <ExistingBusinessRecommendationForm business={existingBusiness} />
                    : <NewBusinessRecommendationForm {...{ phoneNumber }} />)
            }

        </Container>
    )
}