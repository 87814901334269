import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { ActionButtonOnBackground } from "../../shared/components/styled-components/Buttons";

const Container = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 3rem;
`;
const SubmittedMessage = styled.div`
  
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

export function RecommendSuccess() {
    const navigate = useNavigate();

    function goHome() {
        navigate("/");
    }

    function newRec () {
        navigate("/recommend");
    }

    return (
        <Container>
            <SubmittedMessage>תודה רבה, ההמלצה נשמרה :)</SubmittedMessage>
            <ActionsContainer>
                <ActionButtonOnBackground variant="contained" color="success" size="large" onClick={newRec}>
                    המלצה חדשה
                </ActionButtonOnBackground>
                <ActionButtonOnBackground variant="outlined" size="large" onClick={goHome}>
                    לעמוד הבית
                </ActionButtonOnBackground>
            </ActionsContainer>
        </Container>
    )
}