import { api } from "../api";
import { BusinessEventName, IBusinessWithRecommendations } from "../api/axios-client";
import { UseMutationResult } from "@tanstack/react-query";
import * as Types from "../api/axios-client";

export async function addUserBusinessEvent(business: IBusinessWithRecommendations, name: BusinessEventName, mutation: UseMutationResult<void, unknown, Types.AddBusinessEventBody, any>) {
    await mutation.mutateAsync(new api.AddBusinessEventBody({
        businessId: business.id,
        recommendationIds: business.recommendations.map(r => r.id),
        name
    }));
}