import { CheckboxGroupInput } from "../../../shared/validated-form-inputs/CheckboxGroupInput";
import { IControlledFieldProps } from "./types";
import { createCheckboxOptions } from "../fieldUtils";
import { useMemo } from "react";
import { LocationOfServiceProvidedAtField } from "./LocationOfServiceProvidedAtField";
import { ServiceProvidedAt } from "../../../api/axios-client";


type IClubConsumptionOptions = 'inTheClub' | 'online';

export function ClubActivityAtField({ control, fieldParams }: IControlledFieldProps) {

    const values = {
        inTheClub: ServiceProvidedAt.BusinessPremises,
        online: ServiceProvidedAt.Online,
    };
    const options = useMemo(
        () => createCheckboxOptions(Object.keys(values))
            .map((option) => ({ ...option, value: values[option.value as IClubConsumptionOptions] })),
        []
    );

    return (
        <>
            <CheckboxGroupInput
                {...{ options, control }}
                name="serviceProvidedAt"
                label="איפה החוג פועל?"
                rules={{ required: "שדה חובה" }}
            />
            <LocationOfServiceProvidedAtField {...{ control, fieldParams, locationChoices: Object.values(values) }} />
        </>
    )
}