import { useEffect, useState } from "react";
import { IBusinessTypeSimplified, IBusinessWithSimplifiedTypes } from "./business.schema";
import { getBusinessOfUser } from "./business.service";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { BusinessFieldsForm } from "./BusinessFieldsForm";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { HomeOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Form } from "../shared/components/styled-components/Form";
import { FormProvider, useForm } from "react-hook-form";
import { useUpdateBusinessMutation } from "../api/axios-client/Query";
import { api } from "../api";
import { trackEvent, trackException } from "../analytics";
import { IUpdateBusinessFields } from "../api/axios-client";
import { CardContainer } from "../shared/components/styled-components/Containers";
import { ActionButton, ActionButtonOnBackground } from "../shared/components/styled-components/Buttons";
import { ErrorText } from "../shared/components/styled-components/Text";

const Container = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
`;

const Success = styled(CardContainer)`
  font-size: 1rem;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  align-items: center;
`;


export function BusinessManagementPage() {
    const [business, setBusiness] = useState<IBusinessWithSimplifiedTypes | null>(null);
    const updateBusiness = useUpdateBusinessMutation(business?.id || '', { onSuccess: () => {} });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [updated, setUpdated] = useState<boolean>(false);
    const navigate = useNavigate();

    const methods = useForm({
        mode: 'onTouched',
        defaultValues: { ...business, ...business?.fields },
        shouldUnregister: true
    });
    const { handleSubmit, control, reset } = methods;

    const appInsights = useAppInsightsContext();

    useEffect(() => { fetchBusiness() }, []);

    const fetchBusiness = async () => {
        try {
            setIsLoading(true);
            const business = await getBusinessOfUser();
            if (business) {
                setBusiness(business);
                reset({ ...business, ...business?.fields })
            } else {
                setError('העסק שלך עדיין לא הומלץ אצלנו :(');
            }
        } catch (e) {
            trackException('Error fetching business', e as Error );
            setError('משהו השתבש :(');
        } finally {
            setIsLoading(false);
        }
    }


    const submitBusiness = async (businessFields: IUpdateBusinessFields) => {
        try {
            if (business?.id) {
                const { businessTypes, serviceArea, addressInfo, ...businessFieldsToSubmit } = businessFields;
                const businessTypeIds = businessTypes.map((businessType: IBusinessTypeSimplified) => businessType.id);
                const payload = new api.UpdateBusinessFields({
                    ...businessFieldsToSubmit,
                    businessTypeIds
                });
                payload.serviceArea = serviceArea ? serviceArea.map(sa => new api.GeoItems(sa)) : [];
                payload.addressInfo = new api.AddressInfo(addressInfo || {});
                setIsLoading(true);
                await updateBusiness.mutateAsync(payload);
                trackEvent('Business Updated', { businessId: business.id, businessName: business.name })
                setError(null);
                setUpdated(true);
            }
        } catch (e) {
            trackException('Business Update Failed', updateBusiness.error as Error);
            setError('משהו השתבש :(');
        } finally {
            setIsLoading(false);
        }
    }

    const onSubmit = async (data: any) => {
        await submitBusiness(data);
    };

    const navigateHome = () => {
        navigate('/');
    };

    return (
        <Container>
            {business && (
                <Container>
                    <h3>עדכון פרטי העסק</h3>
                    <FormProvider {...methods} >
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <BusinessFieldsForm {...{ control, isUpdatingBusiness: true }} />
                        {(updated && !error) &&
                            <Success>
                                <span>העדכון עבר בהצלחה :)</span>
                                <ActionButton onClick={navigateHome} variant="outlined" size="small" startIcon={<HomeOutlined />}>
                                    לדף הבית
                                </ActionButton>
                            </Success>
                        }
                        {error && <ErrorText>{error}</ErrorText>}
                        {isLoading
                            ? <CircularProgress />
                            : <ActionButtonOnBackground type="submit" variant="contained" color="success">
                                שמירה
                        </ActionButtonOnBackground>
                        }
                    </Form>
                    </FormProvider>
                </Container>
            )}
            {!isLoading && !business && <ErrorText>{error}</ErrorText>}
        </Container>
    )
}