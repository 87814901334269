import styled from "@emotion/styled";


export const CardContainer = styled.div`
  background-color: ${p => p.theme.white};
  border-radius: ${p => p.theme.blockBorderRadius};
  padding: 1.5rem;
  box-shadow: ${p => p.theme.shadow};
`;

export const IconAndTextInlineContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 1rem;
`;