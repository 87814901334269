import { IUser, IUserContext } from "../user/user.schema";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { appInsights } from "../analytics";
import { useSearchParamsState } from "../shared/hooks/useSearchParamsState";
import { StringParam } from "use-query-params";


type ISpecialRouterProps = { children: JSX.Element, user: IUser, setUser: (context: IUserContext) => void };
export const AnyUserRoute = ({ children, user, setUser }: ISpecialRouterProps) => {
    const [isLoading, setIsLoading] = useState(!user.id);
    const location = useLocation();

    useEffect(() => {
        if (!user.id)  getUser();
    })

    const getUser = async () => {
        setIsLoading(true);
        const response = await fetch('/api/user/me');
        if (response.ok) {
            const user = await response.json();
            setUser(user);
            appInsights.setAuthenticatedUserContext(user.id, undefined, true);
        }
        setIsLoading(false);
    }

    if (isLoading) {
        return <CircularProgress />;
    } else if (user.id) {
        return children;
    } else {
        return <Navigate to={appendRedirectUrl('/login',location.pathname + location.search)} />;
    }
}

function appendRedirectUrl(url: string, redirectUrl?: string | null) {
    if (redirectUrl === '/') {
        redirectUrl = null;
    }
    return `${url}${redirectUrl ? `?redirect-url=${encodeURIComponent(redirectUrl)}` : ''}`
}

export function useNavigateOnLogin() {
    const navigate = useNavigate();
    const [redirectUrl] = useSearchParamsState('redirect-url', StringParam);

    return (url: string) => {
        if (url === '/') {
            navigate(redirectUrl || '/');
        } else {
            navigate(appendRedirectUrl(url, redirectUrl));
        }
    }
} 