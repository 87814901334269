import React, { useContext } from "react";
import styled from "@emotion/styled";
import { api } from "../api";
import { StatsLevel } from "../api/axios-client";
import { UserContext } from "../user/user.service";
import { CircularProgress } from "@mui/material";

const RecommendationStatsContainer = styled.div`

`;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
`;

const GroupAnnouncementContainer = styled.div`
  margin-bottom: 3rem;
  display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const GroupAnnouncement = styled.div`
  text-align: center;
  font-size: 1.2rem;
`;
const GroupName = styled.div`
  font-weight: 600;
  display: grid;
  justify-content: center;
  text-align: center;
`;

export function RecommendationStatsBlock() {
    const stats = api.Query.useGetRecommendationsStatsQuery(StatsLevel.Total);
    const { user } = useContext(UserContext);

    if (!stats.isFetching && stats.isFetched && stats.data) {
        return (
            <RecommendationStatsContainer>
                <GroupAnnouncementContainer>
                    <GroupName>
                        {/* TODO: change to name or count when moving to multi-group */}
                        {user.groups[0].name}
                    </GroupName>
                    <GroupAnnouncement>
                        לרשותך {stats.data.recommendations} המלצות ל{stats.data.businesses} עסקים
                    </GroupAnnouncement>
                </GroupAnnouncementContainer>
            </RecommendationStatsContainer>
        )
    }

    if (stats.isFetching) {
        return (
            <LoadingContainer>
                <CircularProgress />
            </LoadingContainer>
        )
    }
    return <></>
}