import { Control } from "react-hook-form";
import { FieldFactory } from "./form-fields/FieldFactory";
import React, { useMemo } from "react";
import { getLocationNormalizedFieldSet } from "./fieldsOfBusinessTypes";
import { IBusinessTypeSimplified } from "../business.schema";
import { IFieldParams } from "./form-fields/types";
import { BusinessTypesFieldsSet } from "../../api/axios-client";

type IBusinessFieldsFactoryProps = {
    businessTypes: IBusinessTypeSimplified[],
    isUpdatingBusiness?: boolean,
    control: Control<any>
}

export function BusinessFieldsFactory({
    businessTypes,
    isUpdatingBusiness = false,
    control
}: IBusinessFieldsFactoryProps): JSX.Element {
    const fieldSets = useMemo(() => businessTypes.map(bt => bt.fieldsSet as BusinessTypesFieldsSet), [businessTypes]);

    const fieldNames = useMemo(() => {
        return getLocationNormalizedFieldSet(fieldSets);
    }, [fieldSets]);

    if (isUpdatingBusiness && fieldNames.includes('serviceArea')) {
        fieldNames[fieldNames.indexOf('serviceArea')] = 'fullServiceArea';
    }
    const fieldParams: IFieldParams =  { allowMultipleServiceAreas: isUpdatingBusiness };
    return (
        <>
            {fieldNames
                .map(fieldName => (
                    <FieldFactory key={fieldName} {...{ control, fieldName, fieldParams }} />
                ))}
        </>
    )
}