import { TextInput } from "../../../shared/validated-form-inputs/TextInput";
import { IControlledFieldProps } from "./types";

type IBusinessNameFieldProps = {label?: string} & IControlledFieldProps
export function NameField({ control, label }: IBusinessNameFieldProps) {
    label = label || 'שם';
    return (
        <TextInput
            name="name"
            label={label}
            control={control}
            rules={{ required: "שדה חובה" }}
        />
    );
}