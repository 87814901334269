import { ICheckboxInputOption } from "../../shared/validated-form-inputs/common";

export function createCheckboxOptions(options: string[]): ICheckboxInputOption[] {
    return options.map(option => ({ value: option, label: option }));
}

export function translateString(str: string, t: (v: string) => string): string {
    return t(`${str}`);
}

export function fieldValuesArrayToString(values: string[], t: (v: string) => string): string {
    let str = '';
    values.forEach((value, i) => {
        const translatedSpa = t(value);
        if (i < values.length - 2)
            str += `${translatedSpa}, `;
        else if (i === values.length - 2)
            str += `${translatedSpa} `;
        else
            str += `ו${translatedSpa}`;
    });
    return str;
}