import { SyntheticEvent, useEffect, useState } from "react";
import { Autocomplete, CircularProgress, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "../../assets/SearchIcon";
import { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { Controller, useFormContext } from "react-hook-form";
import { ICommonInputProps } from "./common";
import { debounce } from "../utils/newUtils";
import { AutocompleteValue } from "@mui/base/AutocompleteUnstyled/useAutocomplete";

interface IAutoCompleteInputProps<TOption, TOptionCategory, TMultiple extends boolean | undefined>
    extends ICommonInputProps {
    useOptions: (search: string,
        option: UseQueryOptions<TOption[]>,
        category: TOptionCategory
    ) => UseQueryResult<TOption[]>,
    optionToString: (option: TOption) => string,
    optionCategory?: TOptionCategory,
    helperText?: string,
    disabled?: boolean,
    icon?: JSX.Element,
    defaultValue?: AutocompleteValue<TOption, TMultiple, true, undefined>,
    multiple?: TMultiple,
}

export function AutoCompleteInput<TOption, TOptionCategory, TMultiple extends boolean | undefined>({
    useOptions,
    optionToString,
    helperText,
    name,
    control,
    label,
    optionCategory,
    icon,
    defaultValue,
    multiple,
    ...props
}: IAutoCompleteInputProps< TOption, TOptionCategory, TMultiple>) {

    const [searchValue, setSearchValue] = useState('');
    const [hasSearchQuery, setHasSearchQuery] = useState(false);
    const options = useOptions(searchValue, {
        enabled: searchValue?.length > 1
    }, optionCategory as TOptionCategory);

    const queryAutocompleteDebounced = debounce((_: SyntheticEvent, searchValue: string, reason: string) => {
        setHasSearchQuery(!(reason === "reset" || !searchValue.length))
        setSearchValue(reason === "reset" ? '' : searchValue);
    }, 500);

    return (<Controller
            name={name}
            control={control}
            shouldUnregister
            defaultValue={defaultValue || (multiple ? [] : null)}
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    {...props}
                    id={name}
                    autoHighlight
                    autoSelect
                    options={options.data || []}
                    multiple={multiple}
                    getOptionLabel={(option) => typeof option === 'string' ? option : optionToString(option)}
                    isOptionEqualToValue={(option, value) => (typeof option === 'string' ? option : optionToString(
                        option)) === (typeof value === 'string' ? value : optionToString(value))}
                    onInputChange={queryAutocompleteDebounced}
                    filterOptions={(x) => x}
                    onChange={(event, item) => {
                        onChange(item);
                    }}
                    value={value}
                    loading={options.isFetching}
                    loadingText="טוען..."
                    noOptionsText={hasSearchQuery ? "לא נמצאו תוצאות" : "התחלו להקליד כדי לחפש"}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        <InputAdornment position="start">
                                            {options.isFetching ? <CircularProgress /> : (icon ?? <SearchIcon />)}
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                )
                            }}
                            label={label}
                            error={options.isError
                                || (!!searchValue && options.data?.length === 0 && !(options.isFetching))}
                            helperText={
                                options.isError ? 'משהו השתבש :('
                                                : searchValue && options.data?.length === 0 && !(options.isFetching)
                                                  ? 'לא נמצאו תוצאות לחיפוש זה'
                                                  : helperText}
                        />
                    )}
                />
            )}
        />
    )
}
