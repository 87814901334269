import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";


const Container = styled.div`
  display: grid;
  grid-gap: 2rem;
  width: 100%;
`;
const HeaderContainer = styled.div`
  display: grid;
  justify-content: center;
`;
const Header = styled.h2`
  margin: 0;
`;

const FormContainer = styled.div`

`;

export function RecommendRoot() {
    return (
        <Container>
            <HeaderContainer>
                <Header>המלצה חדשה</Header>
            </HeaderContainer>
            <FormContainer>
                <Outlet />
            </FormContainer>
        </Container>
    )
}