import { useSearchCity, useUserCity } from "../hooks/geography";
import { Apartment } from "@mui/icons-material";
import { AutoCompleteInput } from "../validated-form-inputs/AutoCompleteInput";
import { IControlledFieldProps } from "../../business/business-fields/form-fields/types";

export function SingleCitySearch({ control, name, label }: IControlledFieldProps) {
    return (
        <AutoCompleteInput
            useOptions={useSearchCity}
            optionToString={x => x}
            name={name || "city"}
            label={label || "עיר"}
            control={control as any}
            icon={<Apartment />}
        />
    )
}