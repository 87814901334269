import { api } from "../api";
import { StatsLevel } from "../api/axios-client";
import { AreaTree } from "./AreaTree";
import styled from "@emotion/styled";
import { useState } from "react";
import { trackEvent } from "../analytics";


const Container = styled.div`

`;
const SummaryContainer = styled.div`

`;
const SummaryHeader = styled.h5`
  margin-block-end: 1rem;
`;
const SummaryItem = styled.div`

`;
const TreeContainer = styled.div`

`;

export function SemanticsPage() {
    const [openedArea, setOpenedArea] = useState<string | null>(null);
    const areas = api.Query.useGetRecommendationsStatsQuery(StatsLevel.Total);

    const setOpen = (area: string | null) => {
        if (openedArea === area) {
            setOpenedArea(null);
        } else {
            trackEvent('User opened area', { area });
            setOpenedArea(area);
        }
    }
    return (
        <Container>
            {areas.isFetched && areas.data && <>
                <SummaryContainer>
                    <SummaryItem>{areas.data.recommendations} המלצות, {areas.data.businesses} עסקים</SummaryItem>
                    <SummaryHeader>קטגוריות:</SummaryHeader>
                </SummaryContainer>
                <TreeContainer>
                    {areas.data.items.map(area => <AreaTree {...{
                        area,
                        isOpen: (openedArea === area.name),
                        setOpen,
                        key: area.name
                    }} />)}
                </TreeContainer>
            </>}
        </Container>
    )
}