import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import React from "react";

type IBusinessTypeAddPromptDialogProps = {
    businessTypeName: string;
    isOpened: boolean;
    confirm: () => void;
    cancel: () => void;
}
export function BusinessTypeAddPromptDialog({ businessTypeName, isOpened, confirm, cancel }: IBusinessTypeAddPromptDialogProps) {
    return (
        <Dialog
            open={isOpened}
            onClose={cancel}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>סוג עסק חדש - {businessTypeName}</DialogTitle>
            <DialogContent>
                <p>
                    להוסיף את {businessTypeName} למאגר הקטגוריות במומלץ.נט?
                </p>

            </DialogContent>
            <DialogActions>
                <Button onClick={confirm} variant="contained" startIcon={<Check />}>כן, בבקשה!</Button>
                <Button onClick={cancel} startIcon={<Close />}>ביטול</Button>
            </DialogActions>
        </Dialog>
    )
}