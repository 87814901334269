import { IControlledFieldProps } from "./types";
import { SingleCitySearch } from "../../../shared/components/SingleCitySearch";

export function ServiceAreaCityField({ control, isOptional }: IControlledFieldProps) {
    let label = "באיזו עיר קיבלת שירות";

    // TODO: remove?
    if (isOptional) label += " (אם רלוונטי)";

    return (
        <SingleCitySearch
            control={control}
            name="serviceAreaCity"
            label={label}
        />
    );
}