import { BusinessCard } from "../business/business-card/BusinessCard";
import styled from "@emotion/styled";
import { useMemo } from "react";
import { IBusinessWithRecommendations } from "../api/axios-client";
import { CardContainer } from "../shared/components/styled-components/Containers";

const Container = styled.div`
  display: grid;
  grid-gap: 2rem;
  margin-top: 2rem;
`;

const NoResultsMessage = styled(CardContainer)`
    font-size: 1.1rem;
  text-align: center;
`;

type IRecommendationProps = {
    businesses: IBusinessWithRecommendations[];
    businessTypeName: string;
}

export function SearchResults({ businesses, businessTypeName }: IRecommendationProps) {
    const sortedBusinesses = useMemo(() => (
        businesses.sort((a, b) => b.recommendations.length - a.recommendations.length)
    ), [businesses]);
    return (
        <Container>
            {!!businesses.length && sortedBusinesses.map((business) => <BusinessCard business={business} key={business.id} />)}
            {!businesses.length && <NoResultsMessage>
                אין אף המלצה ל{businessTypeName} בינתיים 😔
            </NoResultsMessage>}
        </Container>
    )
}