import { useController } from "react-hook-form";
import { TextField } from "@mui/material";
import { ITextInputProps } from "./common";


export function TextInput({ name, label, helpText, control, rules, type, autocomplete }: ITextInputProps) {
    const {
        field,
        fieldState: { error }
    } = useController({ name, control, rules, defaultValue: '' });

    const isRequired = !!(typeof rules?.required === 'object' ? rules.required.value : rules?.required);

    return (
        <TextField
            id={name}
            {...field}
            label={label}
            error={!!error}
            autoComplete={autocomplete}
            type={type || "text"}
            helperText={error?.message ?? helpText}
            required={isRequired}
        />
    );
}