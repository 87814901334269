import { IAddBusiness } from "../business/business.schema";
import { trackException } from "../analytics";


export type IRecommendationAndBusiness = IAddBusiness & {
    text?: string;
}
export async function saveRecommendationAndBusiness(formValues: IRecommendationAndBusiness) {
    const {review, ...business} = formValues;
    const res = await fetch('api/recommendation/and-business', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ business, text: review }),
    });
    if (res.ok) {
        return res.json();
    } else {
        trackException('Save recommendation for new business failed', await res.json());
    }
}

export type IRecommendationForBusiness = IAddBusiness & {businessId: string, text?: string };
export async function saveRecommendationForBusiness(formValues: IRecommendationForBusiness) {
    const {businessId, review, ...business} = formValues;
    const res = await fetch('api/recommendation/for-business', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ business, text: review, businessId }),
    });
    if (res.ok) {
        return res.json();
    } else {
        trackException('Save recommendation for existing business failed', await res.json());
    }
}

export async function checkRecommendationExists(businessId: string): Promise<boolean> {
    const res = await fetch(`/api/recommendation/existing/${businessId}`);
    if (res.ok) {
        return !!(await res.json());
    }
    return false;
}