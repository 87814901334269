import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { ThankYouDialog } from "./ThankYouDialog";
import { setNotificationStatus } from "../user.service";
import { useState } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useNavigateOnLogin } from "../../router/AnyUserRoute";
import { ActionButtonOnBackground } from "../../shared/components/styled-components/Buttons";
import { trackEvent } from '../../analytics';


const Container = styled.div`
    align-self: start;
`;
const Header = styled.h2`

`;
const Text = styled.div`
 font-size: 1.2rem;
  margin-bottom: 1rem;
`;
const CTA = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
`;

const SmallText = styled.div`
  font-size: 0.8rem;
`;
const ButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  justify-content: start;
  margin-top: 0.83rem; // fit default browser margin of h2. Dumb, fix later
`;

export function EnableNotificationsPage() {
    const navigate = useNavigateOnLogin();
    const appInsights = useAppInsightsContext();

    const [isThankYouDialogOpen, setIsThankYouDialogOpen] = useState(false);
    const closeThankYouDialog = () => {
        setIsThankYouDialogOpen(false);
    }

    const navigateToHomePage = () => {
        trackEvent("User Rejected Notifications", {});
        navigate('/');
    }

    const happyNotifications = () => {
        trackEvent("User Set Up Notifications", {});
        setNotificationStatus(true, true);
        setIsThankYouDialogOpen(true);
    }

    const almostHappyNotifications = (analyticsString: string) => {
        trackEvent(analyticsString, {});
        setNotificationStatus(true, false);
        setIsThankYouDialogOpen(true);
    }

    function requestPermission() {
        if (!("Notification" in window)) {
            almostHappyNotifications("User Browser No Notifications Support");
        } else if (Notification.permission === "granted") {
            happyNotifications();
        } else if (Notification.permission !== "denied") {
            // We need to ask the user for permission
            Notification.requestPermission()
                .then((permission) => {
                // If the user accepts, we good
                if (permission === "granted") {
                    happyNotifications();
                } else {
                    trackEvent("User Rejected Notifications In Browser", {});
                    setNotificationStatus(false, true);
                }
            });
        } else { // browser notifications are disabled
            almostHappyNotifications("User Notifications In Browser Are Disabled");
        }
    }

    return (
        <Container>
            <Header>שלום לך!</Header>
            <Text>הגעת לפלטפורמת פה לאוזן דיגיטלי 🚀</Text>
            <Text>זה המקום לעזור לחברים - והממליצים הם המנוע של הקהילה 🚂</Text>
            <Text>האם גם לך יש רצון לעזור? 🤩</Text>
            <div>
                <CTA>אם כן, נשלך התראות כשצריך עזרה 📨</CTA>
                <Text><SmallText>מבטיחים לא להציף אותכם סתם 😊</SmallText></Text>
            </div>

            <ButtonsContainer>
                <ActionButtonOnBackground variant="contained" size="medium" color="success" onClick={requestPermission}>
                    כן, אשמח לעזור 🚀
                </ActionButtonOnBackground>
                <ActionButtonOnBackground id="no-thanks" variant="outlined" size="medium" onClick={navigateToHomePage}>
                    לא, תודה
                </ActionButtonOnBackground>
            </ButtonsContainer>
            <ThankYouDialog isOpen={isThankYouDialogOpen} closeDialog={closeThankYouDialog} />
        </Container>
    );
}
