import { useController, useFormContext } from "react-hook-form";
import { Checkbox, FormControlLabel, FormGroup, FormHelperText, FormLabel } from "@mui/material";
import { ICheckboxGroupInputProps } from "./common";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

export function CheckboxGroupInput(props: ICheckboxGroupInputProps) {
    const { name, label, helpText, control, rules, options } = props;
    const { field, fieldState } = useController({ name, control, rules, defaultValue: [] });
    const { setError, clearErrors } = useFormContext();
    const isRequired = rules?.required;



    const { t } = useTranslation('fieldValues');

    const onChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            if (isRequired) clearErrors(name);
            field.onChange([
                ...field.value,
                event.target.value
            ]);
        } else {
            if (isRequired && field.value.length === 1) {
                setError(name, { type: 'required', message: 'בחרו לפחות אפשרות אחת' });
            }
            field.onChange(
                field.value.filter(
                    (value: string) => value !== event.target.value
                )
            );
        }
    }


    return (
        <FormGroup>
            <FormLabel error={!!fieldState.error}>{label}{isRequired && '*'}</FormLabel>
            {options.map((option) => (
                <FormControlLabel
                    key={option.value}
                    label={t(option.label)}
                    control={
                        <Checkbox
                            value={option.value}
                            checked={field.value.includes(option.value)}
                            onChange={onChange}
                        />
                    }
                />
            ))}
            {helpText && <FormHelperText>{helpText}</FormHelperText>}
            {fieldState.error && <FormHelperText error>בחרו לפחות אפשרות אחת</FormHelperText>}
        </FormGroup>
    );
}