import { IBusinessWithSimplifiedTypes, IUpdateBusiness } from "./business.schema";
import { trackEvent, trackException } from "../analytics";
import { IBusinessWithRecommendations } from "../api/axios-client";


export async function searchBusinessByPhone(phone: string): Promise<IBusinessWithRecommendations | null> {
    const res = await fetch(`/api/business/search-by-phone/${phone}`);
    if (res.ok) {
        return res.json();
    }
    return null;
}

export async function getBusinessOfUser(): Promise<IBusinessWithSimplifiedTypes | null> {
    const res = await fetch('api/business/of-user');
    if (res.ok) {
        return res.json();
    } else if (res.status === 404) {
        return null;
    } else if (res.status === 401) {
        trackException('Unauthorized', new Error('Unauthorized'));
        window.location.href = '/login';
    }
    throw new Error('Error getting business of user');
}

export async function updateBusiness(businessId: string, business: IUpdateBusiness): Promise<void> {
    // currently only business that has the same phone as user can be updated
    const res = await fetch(`api/business/${businessId}`, {
        method: 'POST',
        body: JSON.stringify(business),
        headers: {
            'Content-Type': 'application/json',
        }
    });
    if (res.ok) {
        trackEvent('Business Updated', {});
        return;
    }
    throw new Error('Error updating business');
}
