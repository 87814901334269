import { ActionButtonOnBackground } from "../styled-components/Buttons";
import { ContactPhoneOutlined } from "@mui/icons-material";
import { IOsPickerEnableGuide } from "./IOsPickerEnableGuide";
import { useState } from "react";
import { Dialog } from "@mui/material";
import { AndroidPickerEnableGuide } from "./AndroidPickerEnableGuide";
import { ErrorText } from "../styled-components/Text";

type IContactPickerProps = {
    handleContacts: (contacts: IContact[]) => void;
}

export function ContactPicker({ handleContacts }: IContactPickerProps) {
    const [iosModalOpen, setIosModalOpen] = useState(false);
    const [androidModalOpen, setAndroidModalOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const closeIosModal = () => setIosModalOpen(false);

    function getOS(): 'ios' | 'android' | null {
        const userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        let os: 'ios' | 'android' | null = null;

        if (iosPlatforms.indexOf(platform) !== -1 || navigator.maxTouchPoints > 0 && typeof navigator.standalone !== "undefined") {
            os = 'ios';
        } else if (/Android/.test(userAgent)) {
            os = 'android';
        }
        return os;
    }

    const isFeatureSupported = ('contacts' in navigator && 'ContactsManager' in window);

    const openContactPicker = async () => {
        if (isFeatureSupported) {
            try {
                const contacts = await navigator.contacts.select(['name', 'tel']);
                handleContacts(contacts);
            } catch (err) {
                if (getOS() === 'android') {
                    setAndroidModalOpen(true);
                } else {
                    setError('משהו השתבש :(');
                }
            }

        } else if (getOS() === 'ios') {
            setIosModalOpen(true);
        } else if (getOS() === 'android') {
            setAndroidModalOpen(true);
        }
    }

    return (
        <>
            {(isFeatureSupported || !!getOS()) &&
                <>
                    <ActionButtonOnBackground variant="outlined"
                                              onClick={openContactPicker}
                                              startIcon={<ContactPhoneOutlined />}>
                        לבחור מאנשי קשר
                    </ActionButtonOnBackground>
                    <Dialog open={iosModalOpen} onClose={closeIosModal}>
                        <IOsPickerEnableGuide close={closeIosModal} />
                    </Dialog>
                    <Dialog open={androidModalOpen} onClose={() => setIosModalOpen(false)}>
                        <AndroidPickerEnableGuide />
                    </Dialog>
                </>
            }
            {error && <ErrorText>{error}</ErrorText>}
        </>
    )
}