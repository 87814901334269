import { ApplicationInsights, ICustomProperties } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_ANALYTICS_ENV === 'production'
                          ? 'InstrumentationKey=6e1cbbb0-650f-4467-8d60-11ab61937386;IngestionEndpoint=https://germanywestcentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://germanywestcentral.livediagnostics.monitor.azure.com/'
                          : 'InstrumentationKey=g45c9129-ff6b-486e-a6c1-dc60efb6fc54;IngestionEndpoint=https://germanywestcentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://germanywestcentral.livediagnostics.monitor.azure.com/',
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        loggingLevelConsole: 1,
        autoTrackPageVisitTime: false,
    }
});

export function trackEvent(name: string, attribures: ICustomProperties) {
    // appInsights.trackEvent({ name }, attribures)
}

export function trackException(name: string, exception: Error) {
    // appInsights.trackException({ exception }, { name })
}