import { BusinessEventName, IBusinessWithRecommendations } from "../../../api/axios-client";
import styled from "@emotion/styled";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import { DirectionsOutlined, LocationOnOutlined, Map } from "@mui/icons-material";
import { trackEvent } from "../../../analytics";
import { WazeIcon } from "../../../assets/WazeIcon";
import { ActionButton } from "../../../shared/components/styled-components/Buttons";
import { addUserBusinessEvent } from "../../../shared/events";
import { api } from "../../../api";
import { sleep } from "../../../shared/utils/newUtils";


const Container = styled.div`
  .MuiButton-text {
    font-size: 1rem;
    font-weight: 400;
  }
`;
const AddressStringContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;


export function AddressDisplayField({ business }: { business: IBusinessWithRecommendations }) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const addEventMutation = api.Query.useAddBusinessEventMutation();

    const address = business.addressInfo;
    if (!address?.city) return null;

    const addressString = (() => {
        let str = '';
        if (address.street) str += address.street;
        if (address.buildingNumber) str += ` ${address.buildingNumber}`;
        if (str) str += ', ';
        str += address.city;
        return str;
    })();

    const isFullAddress = !!address.city && !!address.street && !!address.buildingNumber;

    const trackNavigation = async (nav: 'Waze' | 'GoogleMap') => {
        await sendNavEvent();
        trackEvent(`Navigating with ${nav}`, {
            properties: {
                businessName: business.name,
                isFullAddress,
            }
        });
    }

    async function sendNavEvent() {
        setIsLoading(true);
        addUserBusinessEvent(business, BusinessEventName.NavigatedToBusiness, addEventMutation);
        await sleep(1000);
        setIsLoading(false);
    }

    async function openDialog() {
        trackEvent("Open Business Address Dialog", { businessName: business.name });
        setDialogOpen(true);
        await addUserBusinessEvent(business, BusinessEventName.OpenedNavModal, addEventMutation);
    }

    const openWaze = async () => {
        await trackNavigation('Waze');
        if (isFullAddress)
            window.open(`https://waze.com/ul?q=${addressString}`, '_blank');
        else
            window.open(`https://waze.com/ul?q=${business.name}, ${address.city}`, '_blank');

        setDialogOpen(false);
    }

    const openGoogleMaps = async () => {
        await trackNavigation('GoogleMap');
        if (isFullAddress)
            window.open(`https://www.google.com/maps/search/?api=1&query=${addressString}`, '_blank');
        else
            window.open(`https://www.google.com/maps/search/?api=1&query=${business.name}, ${address.city}`, '_blank');

        setDialogOpen(false)
    }
    return (
        isFullAddress ?
            <Container>
                <ActionButton
                    onClick={() => openDialog()}
                    variant="outlined"
                    startIcon={<DirectionsOutlined />}
                    size="large">
                    לנווט
                </ActionButton>

                <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="xl" fullWidth>
                    <DialogTitle>
                        {business.name}
                    </DialogTitle>
                    <DialogContent>
                        <AddressStringContainer>
                            <LocationOnOutlined />
                            {addressString}
                        </AddressStringContainer>
                    </DialogContent>
                    <DialogActions>
                        {isLoading && <CircularProgress />}
                        {!isLoading && <>
                            <ActionButton variant="contained"
                                          onClick={openWaze}
                                          startIcon={<WazeIcon />}
                                          color="waze"
                                          size="medium">
                               וייז
                            </ActionButton>
                            <ActionButton variant="outlined" onClick={openGoogleMaps} startIcon={<Map />} size="medium">
                                מפות גוגל
                            </ActionButton>
                        </>}
                    </DialogActions>
                </Dialog>
            </Container>
            : <></>
    )
}