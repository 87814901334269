import styled from "@emotion/styled";
import { fieldValuesArrayToString, translateString } from "../fieldUtils";
import { useTranslation } from "react-i18next";

const Container = styled.div`
    font-size: 1rem;
`;

export function CommonCheckboxDisplay({ name, value }: { name: string, value: string | any[] }) {
    let tKey = useTranslation('fieldKeys');
    const tValue = useTranslation('fieldValues');

    const translatedKey = translateString(name, tKey.t);
    const translatedValue = Array.isArray(value)
                            ? fieldValuesArrayToString(value, tValue.t)
                            : translateString(value, tValue.t);

    return (
        <Container>
            {translatedKey}{translatedValue}
        </Container>
    )
}