import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { BusinessTypeSearchField } from "../shared/components/business-type-search-field/BusinessTypeSearchField";
import React, { useContext, useState } from "react";
import { UserContext } from "../user/user.service";
import { SearchResults } from "./SearchResults";
import { CircularProgress } from "@mui/material";
import { AddBusiness, Group, Settings } from "@mui/icons-material";
import { useSearchParamsState } from "../shared/hooks/useSearchParamsState";
import { trackEvent, trackException } from "../analytics";
import { useSearchBusinessTypes } from "../shared/components/business-type-search-field/searchBusinessTypeLogic";
import { JsonParam, StringParam } from 'use-query-params';
import { api } from "../api";
import { getServiceProvidedAtByFieldSet } from "../business/business-fields/fieldsOfBusinessTypes";
import { SearchLocationField } from "./SearchLocationField";
import { useUserCity } from "../shared/hooks/geography";
import { BusinessTypesFieldsSet, GeoItemKind, IGeoItems } from "../api/axios-client";
import { ActionButtonOnBackground } from "../shared/components/styled-components/Buttons";
import { RecommendationStatsBlock } from "./RecommendationStatsBlock";

const Container = styled.div`
  justify-items: center;
  align-content: center;
  width: 100%;
`;

const SearchContainer = styled.div`

`;

const ResultsContainer = styled.div`

`;

const ByCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  justify-content: center;
  text-align: center;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  margin-top: 5rem;
`;

const FieldsContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
`;

export function HomePage() {
    const userContext = useContext(UserContext);
    const iAmAdminOfGroup = userContext.user.groups.find(group => group?.roles.length > 0);
    const userCity = useUserCity();
    const userCityArea: IGeoItems | null = userCity
        ? { value: userCity.value, kind: GeoItemKind.GeneratedArea }
        : userCity;
    const [businessTypeName, setBusinessTypeName] = useSearchParamsState('search', StringParam);
    const [geoAreas, setGeoAreas] = useSearchParamsState('areas', JsonParam);
    const [geoItems, setGeoItems] = useState<api.GeoItems[]>(
        geoAreas?.length ? geoAreas.filter(Boolean) : userCityArea ? [userCityArea] : []
    );
    const businessType = useSearchBusinessTypes(businessTypeName || '');
    const businessTypeId = businessType.data?.[0]?.id || '';
    const serviceProvidedAt = getServiceProvidedAtByFieldSet(businessType.data?.[0]?.fieldsSet || BusinessTypesFieldsSet.UnknownType);

    const setGeoItemsFromNames = (geoItems: api.GeoItems[]) => {
        setGeoAreas(geoItems);
        setGeoItems(geoItems);
    };

    // TODO: add field
    const recommendations = api.Query.useSearchGroupRecommendationsQuery(
        {
            groupId: userContext.user.groups[0].id,
            businessTypeId,
            geoItems,
            serviceProvidedAt
        }, {
            enabled: Boolean(businessTypeId),
            onSuccess: (results) => trackEvent(
                "Search Business Type Results",
                { businessTypeName, numberOfResults: results.length }
            ),
            onError: (error: Error) => {
                console.error(error);
                trackException(error.message, error);
            },
            // select: data => data.map(dtoBusiness => translateBusinessTypes(dtoBusiness))
        });

    const navigate = useNavigate();

    const redirectToRecommend = () => {
        trackEvent("New Recommendation Button Clicked", {});
        let url = '/recommend';
        if (businessTypeName) url += `?businessType=${businessTypeName}`;
        navigate(url);
    };

    const redirectToMyBusiness = () => {
        trackEvent("Manage Business Button Clicked", {});
        navigate('/my-business');
    };


    const redirectToGroup = (groupId: string) => {
        trackEvent("Group management button clicked", {});
        navigate(`/group/${groupId}`);
    };

    const redirectToCategories = () => {
        trackEvent("Categories button clicked", {});
        navigate('/categories');
    };

    const SearchPage = () => (
        <>

            <SearchContainer>
                <RecommendationStatsBlock />
                <ByCategoryContainer>
                    <ActionButtonOnBackground onClick={redirectToCategories} variant="outlined">
                        בחירה לפי קטגוריה
                    </ActionButtonOnBackground>
                    <span>- או -</span>
                </ByCategoryContainer>
                <FieldsContainer>
                    <BusinessTypeSearchField selectBusinessType={type => setBusinessTypeName(type?.name)}
                                             defaultValue={businessType.data?.[0]} />
                    {recommendations.data &&
                        <SearchLocationField
                            setGeoItems={geoItems => setGeoItemsFromNames(geoItems)}
                            {...{ geoItems, serviceProvidedAt }} />
                    }
                </FieldsContainer>
            </SearchContainer>

            {recommendations.isFetching && <CircularProgress />}
            {recommendations.data &&
                <ResultsContainer>
                    <SearchResults businesses={recommendations.data} businessTypeName={businessTypeName || ''} />
                </ResultsContainer>
            }
            <ActionsContainer>
                <ActionButtonOnBackground id="NewRecommendationButton"
                                          onClick={redirectToRecommend}
                                          startIcon={<AddBusiness />}
                                          size="large"
                                          color="success"
                                          variant="contained">
                    {businessTypeName ? `להוסיף המלצה ל${businessTypeName}` : 'להוסיף המלצה'}
                </ActionButtonOnBackground>
                {userContext.hasBusiness &&
                    <ActionButtonOnBackground onClick={redirectToMyBusiness}
                                              startIcon={<Settings />}
                                              variant="outlined"
                                              size="large">
                        העסק שלי
                    </ActionButtonOnBackground>}
                {iAmAdminOfGroup &&
                    <ActionButtonOnBackground variant="outlined"
                                              onClick={() => redirectToGroup(iAmAdminOfGroup.identifier)}
                                              size="large"
                                              startIcon={<Group />}>
                        ניהול קבוצה
                    </ActionButtonOnBackground>}
                                  
            </ActionsContainer>
        </>
    )

    return (
        <Container>
            {userContext.user.id && <SearchPage />}
            {!userContext.user && <CircularProgress />}
        </Container>
    );
}
