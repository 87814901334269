import { useNavigate, useParams } from "react-router-dom";
import { useGetBusinessWithRecommendationsQuery } from "../api/axios-client/Query";
import styled from "@emotion/styled";
import { BusinessCard } from "./business-card/BusinessCard";
import { RecommendationCard } from "../recommendation/RecommendationCard";
import { AddReactionOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { trackEvent } from "../analytics";
import { ActionButtonOnBackground } from "../shared/components/styled-components/Buttons";

const Container = styled.div`
  display: grid;
  grid-gap: 1rem;
  @media (min-width: 768px) {
    margin-top: 1rem;
  }
`;



export function BusinessViewPage() {
    const { businessPhone } = useParams<{ businessPhone: string }>();
    const business = useGetBusinessWithRecommendationsQuery({ phoneNumber: businessPhone || '' });
    const navigate = useNavigate();

    const redirectToJoinRecommendation = () => {
        trackEvent("Redirect to Join Recommendation", {
            properties: {businessName: business.data?.name}
        });
        navigate(`/recommend/${business.data?.phoneNumber}`);
    }
    if (business?.data?.name) {
        return (
            <Container>
                <BusinessCard business={business.data} isFullDetails={true} />
                <ActionButtonOnBackground variant="contained" color="success" size="large"
                        onClick={redirectToJoinRecommendation}
                        startIcon={<AddReactionOutlined />}>
                    להצטרף להמלצה
                </ActionButtonOnBackground>
                {business.data.recommendations.map(r => (
                    <RecommendationCard recommendation={r} key={r.id} />
                ))}
            </Container>
        )
    } else if (business.isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    } else {
        return (
            <Container>
                No business phone number provided
            </Container>
        )
    }
}