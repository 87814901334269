import { api } from "../../api";
import { BusinessTypesFieldsSet } from "../../api/axios-client";
import { fieldSetsLocationChoice, isFieldChoosableLocation } from "../../shared/utils/geoUtils";

export type IFieldProps = {
    name: string;
    isGeneric?: boolean;
}
export const fieldTypes = {
    'name': { name: 'name', isGeneric: true },
    'businessName': { name: 'name' },
    'serviceArea': { name: 'serviceArea', isGeneric: true },
    'optionalServiceArea': { name: 'serviceArea' },
    'placeName': { name: 'name' },
    'hobbyClubName': { name: 'name' },
    'phone': { name: 'phone', isGeneric: true },
    'externalLink': { name: 'externalLink', isGeneric: true },
    'addressInfo': { name: 'addressInfo', isGeneric: true },
    'serviceProvidedAtOffline': { name: 'serviceProvidedAt' },
    'optionalAddressInfo': { name: 'addressInfo' },
    'serviceProvidedAt': { name: 'serviceProvidedAt', isGeneric: true },
    'ageStages': { name: 'ageStages' }, // kids -> seniors
    'educationStages': { name: 'educationStages', isGeneric: true }, // kindergarten -> university
    'kidsEducationStages': { name: 'educationStages' }, // kindergarten -> high school
    'clubActivityAt': { name: 'serviceProvidedAt' },
    'fullServiceArea': { name: 'serviceArea' },
    'serviceAreaCity': { name: 'serviceArea' },
} as const;

export type IFieldType = keyof typeof fieldTypes;

export const fieldSets: Record<BusinessTypesFieldsSet, IFieldType[]> = {
    [BusinessTypesFieldsSet.UnknownType]: [
        'businessName',
        'serviceProvidedAt',
        'optionalServiceArea',
        'optionalAddressInfo',
    ],
    [BusinessTypesFieldsSet.HomeService]: [
        'businessName',
        'serviceArea',
        'externalLink',
    ],
    [BusinessTypesFieldsSet.BusinessPremisesService]: [
        'businessName',
        'addressInfo',
        'externalLink',
    ],
    [BusinessTypesFieldsSet.Attraction]: [
        'businessName',
        'addressInfo',
        'externalLink',
        'ageStages',
    ],
    [BusinessTypesFieldsSet.HomeAndPremisesService]: [
        'businessName',
        'serviceProvidedAtOffline', // home or on premises
        'optionalAddressInfo',
        'optionalServiceArea',
        'externalLink',
    ],
    [BusinessTypesFieldsSet.AnywhereLessons]: [
        'businessName',
        // 'lessonGroupOrPersonal',
        'serviceProvidedAt', // home or on premises, or online
        'ageStages', // kids, teens, adults, seniors
        'optionalAddressInfo',
        'optionalServiceArea',
        'externalLink'
    ],
    [BusinessTypesFieldsSet.OnPremisesLessons]: [
        'businessName',
        // 'lessonGroupOrPersonal',
        'ageStages',
        'optionalAddressInfo',
        'externalLink'
    ],
    [BusinessTypesFieldsSet.SchoolKidsLessons]: [
        'businessName',
        'educationStages', // תרום בית הספר, בית ספר יסודי, חטיבת ביניים, תיכון, השלמת בגרויות
        // 'lessonGroupOrPersonal',
        'serviceProvidedAt', // home or on premises, or online
        'optionalAddressInfo',
        'optionalServiceArea',
        'externalLink',
    ],
    [BusinessTypesFieldsSet.ChildrenHobbyClub]: [
        'hobbyClubName',
        'kidsEducationStages', // תרום בית הספר, בית ספר יסודי, חטיבת ביניים, תיכון
        'clubActivityAt', // onprem or online
        'addressInfo',
        'externalLink',
    ],
    [BusinessTypesFieldsSet.Restaurant]: [
        'placeName',
        // 'restaurantOrderOptions',
        'optionalAddressInfo',
        // 'optionalDeliveryArea',
        'externalLink',
        // 'kashrut',
    ],
    [BusinessTypesFieldsSet.Shop]: [
        'businessName',
        // 'purchaseOptions',
        'optionalAddressInfo',
        // 'optionalDeliveryArea',
        'externalLink',
    ],
    // nonBusinessPlace: [
    //     'placeName',
    //     'whereIsIt',
    //     'externalLink',
    // ]
};

export function getServiceProvidedAtByFieldSet(key: BusinessTypesFieldsSet): api.ServiceProvidedAt[] {
    if (fieldSets[key]?.includes('serviceProvidedAt')) {
        return Object.values(api.ServiceProvidedAt);
    }

    if (fieldSets[key]?.includes('clubActivityAt')) {
        return [api.ServiceProvidedAt.BusinessPremises, api.ServiceProvidedAt.Online];
    }

    if (fieldSets[key]?.includes('addressInfo')) {
        return [api.ServiceProvidedAt.BusinessPremises];
    }

    if (fieldSets[key]?.includes('serviceArea')) {
        return [api.ServiceProvidedAt.CustomerPremises];
    }

    return [api.ServiceProvidedAt.BusinessPremises, api.ServiceProvidedAt.CustomerPremises];
}


// combine field sets of different business types into one array
// if these business types has the field with the same name, add a generic field with the same name instead
export function getFieldSet(fieldSetNames: BusinessTypesFieldsSet[]): IFieldType[] {
    type IUniquenessDetector = Record<string, { occurrences: number, fieldName: string }>;
    const uniquenessCounter: IUniquenessDetector = {} as IUniquenessDetector;
    for(const name of fieldSetNames) {
        for(const fieldName of fieldSets[name]) {
            const fieldTypeName = fieldTypes[fieldName].name;
            uniquenessCounter[fieldTypeName] = uniquenessCounter[fieldTypeName]
                ? {
                    occurrences: uniquenessCounter[fieldTypeName].occurrences + 1,
                    fieldName
                }
                : { occurrences: 1, fieldName };
        }
    }

    const fields: string[] = [];
    for(const fieldTypeName in uniquenessCounter) {
        if (uniquenessCounter[fieldTypeName].occurrences === 1) {
            fields.push(uniquenessCounter[fieldTypeName].fieldName);
        } else {
            const genericField = Object.values(fieldTypes)
                                       .find((fieldType: IFieldProps) => fieldType.name === fieldTypeName && (fieldType.isGeneric));
            if (genericField) {
                fields.push(genericField.name);
            }
        }
    }

    return fields as IFieldType[];
}

export function getLocationNormalizedFieldSet(fieldSetNames: BusinessTypesFieldsSet[]): IFieldType[] {
    const names = getFieldSet(fieldSetNames);
    const locationChoice = fieldSetsLocationChoice(fieldSetNames)
    if (locationChoice)
        return names.filter(name => !isFieldChoosableLocation(locationChoice, name));
    return names;
}


