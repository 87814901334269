import { IControlledFieldProps } from "./types";
import { CheckboxGroupInput } from "../../../shared/validated-form-inputs/CheckboxGroupInput";
import { createCheckboxOptions } from "../fieldUtils";
import { LocationOfServiceProvidedAtField } from "./LocationOfServiceProvidedAtField";
import { ServiceProvidedAt } from "../../../api/axios-client";


type IConsumptionOptionsProps = { medium?: 'offline' | 'onlineOffline' } & IControlledFieldProps

export function ServiceProvidedAtField({ control, medium = 'offline', fieldParams }: IConsumptionOptionsProps) {
    const locationChoices = [
        ServiceProvidedAt.BusinessPremises,
        ServiceProvidedAt.CustomerPremises,
    ];
    if (medium === 'onlineOffline') {
        locationChoices.push(ServiceProvidedAt.Online);
    }

    const options = createCheckboxOptions(locationChoices);


    return (
        <>
            <CheckboxGroupInput
                {...{ options, control }}
                name="serviceProvidedAt"
                label="השירות ניתן..."
                rules={{ required: true }}
            />
            <LocationOfServiceProvidedAtField {...{ fieldParams, control, locationChoices }} />
        </>
    )
}