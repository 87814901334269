import styled from "@emotion/styled";
import { IRecommendationsStatsItem, StatsLevel } from "../api/axios-client";
import { api } from "../api";
import { useState } from "react";
import { CategoryTree } from "./CategoryTree";
import { ITreeButtonProps } from "./TreeButton";
import { CircularProgress } from "@mui/material";
import { ChildrenContainer, SemanticButton } from "./SharedSemanticComponents";
import { trackEvent } from "../analytics";

const Container = styled.div`

`;

const CategoriesContainer = styled(ChildrenContainer)`
  margin: 0 1.5rem;
  padding-left: 1rem;
`;

type IAreaTreeProps = {
    area: IRecommendationsStatsItem;
    setOpen: (name: string) => void;
} & ITreeButtonProps;

export function AreaTree({ area, isOpen, setOpen }: IAreaTreeProps) {
    const [openedCategory, setOpenedCategory] = useState<string | null>(null);
    const onClick = () => setOpen(area.name);

    const openCategory = (category: string | null) => {
        if (openedCategory === category) {
            setOpenedCategory(null);
        } else {
            trackEvent('User opened category', { category });
            setOpenedCategory(category);
        }
    }

    const label = `${area.name} (${area.businesses})`;

    const categories = api.Query.useGetRecommendationsStatsQuery(StatsLevel.Area, area.name, { enabled: isOpen });

    return (
        <Container>
            <SemanticButton {...{isOpen, onClick, label}} variant="outlined" />
            {isOpen && categories.isFetched && categories.data &&
                <CategoriesContainer>
                    {categories.data.items.map(category => <CategoryTree {...{
                        category,
                        isOpen: openedCategory === category.name,
                        setOpen: openCategory,
                        key: category.name
                    }} />)}
                </CategoriesContainer>
            }
            {categories.isFetching && <CircularProgress />}
        </Container>
    )
}