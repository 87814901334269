import { UseQueryOptions } from "@tanstack/react-query";
import { api } from "../../api";
import { useContext } from "react";
import { UserContext } from "../../user/user.service";
import { GeoItems } from "../../api/axios-client";

export function useSearchCity(search: string, options?: UseQueryOptions<string[]>) {
    return api.Query.useSearchCitiesQuery({ search }, options);
}

export function useUserCity(): GeoItems | null {
    const userContext = useContext(UserContext);

    return userContext?.user?.addressInfo?.city
        ? new api.GeoItems({value: userContext.user.addressInfo.city , kind: api.GeoItemKind.City})
        : null;
}

export function useSearchGeoItems(search: string,
    options?: UseQueryOptions<api.GeoItems[]>,
    geoItemKinds?: api.GeoItemKind[]
) {
    return api.Query.useSearchGeoItemsQuery({ search, geoItemKinds }, options);
}