import { Phone } from "@mui/icons-material";
import { BusinessEventName, IBusinessWithRecommendations } from "../../../api/axios-client";
import { trackEvent } from "../../../analytics";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { ActionButton } from "../../../shared/components/styled-components/Buttons";
import { addUserBusinessEvent } from "../../../shared/events";
import { ContactByPhoneDialog } from "../../../shared/components/display-fields/ContactByPhoneDialog";
import { api } from "../../../api";
import { sleep } from "../../../shared/utils/newUtils";

const PhoneContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .MuiButton-text {
    font-size: 1.2rem;
    font-weight: 400;
  }
`;


export function PhoneDisplayField({business}: {business: IBusinessWithRecommendations}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const addEventMutation = api.Query.useAddBusinessEventMutation();
    const analyticProperties = {
        businessName: business.name,
        businessTypes: business.businessTypes.map(bt => bt.name).join(', '),
        numberOfRecommendations: business.recommendations.length,
    }

    async function addPhoneEvent(eventName: BusinessEventName) {
        await addUserBusinessEvent(business, eventName, addEventMutation);
    }

    async function openDialog() {
        trackEvent("Open Contact Business Dialog", analyticProperties);
        setDialogOpen(true);
        await addPhoneEvent(BusinessEventName.OpenedContactModal);
    }

    async function dialAnalyticFunc() {
        trackEvent("Call Business",{
            properties: analyticProperties,
        });
        addPhoneEvent(BusinessEventName.ContactedBusiness);
        await sleep(800);
    }

    async function whatsAppAnalyticFunc() {
        trackEvent("Whatsapp Business", {
            properties: analyticProperties,
        });
        addPhoneEvent(BusinessEventName.ContactedBusiness);
        await sleep(800);
    }

    return (
        <PhoneContainer>
            <ActionButton onClick={openDialog} startIcon={<Phone />} size="large" variant="contained" color="success">
                לצור קשר
            </ActionButton>

            <ContactByPhoneDialog {...{ whatsAppAnalyticFunc, dialAnalyticFunc }}
                                  name={business.name}
                                  phone={business.phoneNumber}
                                  isOpened={dialogOpen}
                                  setIsOpened={setDialogOpen} />
            </PhoneContainer>
    )
}