import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const MButton = styled(Button)`
  border-radius: ${p => p.theme.blockBorderRadius};
`;

export const ActionButton = styled(MButton)`
  &.MuiButton-sizeLarge {
    padding: 0.7rem 1.5rem;
    font-size: 1.2rem;
  }

  &.MuiButton-sizeMedium {
    padding: 0.5rem 1.2rem;
    font-size: 1rem;
  }

  &.MuiButton-contained, &.MuiButton-outlined {
    box-shadow: 0 0 10px rgb(62 68 128 / 25%);
  }
`;

export const ActionButtonOnBackground = styled(ActionButton)`
  &.MuiButton-contained, &.MuiButton-outlined {
    box-shadow: 2px 6px 14px rgb(62, 68, 128, 0.25);
  }
`;

export const ChipButton = styled(Button)`
  border-radius: ${p => p.theme.blockBorderRadius};
  text-align: start;
  
  &.MuiButton-sizeLarge {
    font-size: 1.2rem;
    font-weight: 400;
  }
  &.MuiButton-sizeMedium {
    font-size: 1.2rem;
    font-weight: 400;
    padding: 0;
  }
`;