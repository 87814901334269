import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import { LoginPage } from "../user/LoginPage";
import { UnauthorizedPage } from "../user/UnauthorizedPage";
import { SignupPage } from "../user/SignupPage";
import { HomePage } from "../home/HomePage";
import React from "react";
import { EnableNotificationsPage } from "../user/WelcomePage/EnableNotificationsPage";
import { BusinessManagementPage } from "../business/BusinessManagementPage";
import { AnyUserRoute } from "./AnyUserRoute";
import { BusinessViewPage } from "../business/BusinessViewPage";
import { IRouterParams } from "../shared/types";
import { RecommendRoot } from "../recommendation/add-recommendation/RecommendRoot";
import { RecommendSuccess } from "../recommendation/add-recommendation/RecommendSuccess";
import { RecommendBusiness } from "../recommendation/add-recommendation/RecommendBusiness";
import { RecommendGetPhone } from "../recommendation/add-recommendation/RecommendGetPhone";
import { GroupViewPage } from "../group/GroupViewPage";
import { SemanticsPage } from "../semantics-tree/SemanticsPage";

export const MainRouter = ({ user, setUser }: IRouterParams) => createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/we-are-not-acquainted" element={<UnauthorizedPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/recommend" element={
                <AnyUserRoute {...{ user, setUser }}>
                    <RecommendRoot />
                </AnyUserRoute>}
                >
                    <Route path="/recommend/success" element={
                        <AnyUserRoute {...{ user, setUser }}>
                            <RecommendSuccess />
                        </AnyUserRoute>}
                    />
                    <Route path="/recommend/:phoneNumber" element={
                        <AnyUserRoute {...{ user, setUser }}>
                            <RecommendBusiness />
                        </AnyUserRoute>}
                    />
                    <Route path="" element={
                        <AnyUserRoute {...{ user, setUser }}>
                            <RecommendGetPhone />
                        </AnyUserRoute>}
                    />
            </Route>
            <Route path="/welcome" element={
                <AnyUserRoute {...{user, setUser}}>
                    <EnableNotificationsPage />
                </AnyUserRoute>}
            />

            <Route path="/my-business" element={
                <AnyUserRoute {...{user, setUser}}>
                    <BusinessManagementPage />
                </AnyUserRoute>}
            />
            <Route path="/group/:groupIdentifier" element={
                <AnyUserRoute {...{user, setUser}}>
                    <GroupViewPage />
                </AnyUserRoute>}
            />
            <Route path="/business/:businessPhone" element={
                <AnyUserRoute {...{user, setUser}}>
                    <BusinessViewPage />
                </AnyUserRoute>}
            />
            <Route path="/categories" element={
                <AnyUserRoute {...{user, setUser}}>
                    <SemanticsPage />
                </AnyUserRoute>}
            />
            <Route path="/" element={
                <AnyUserRoute {...{user, setUser}}>
                    <HomePage />
                </AnyUserRoute>}
            />
        </>
    )
);