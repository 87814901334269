import { ActionButton } from "../styled-components/Buttons";
import { DialogContent, DialogTitle } from "@mui/material";
import safari from "./ios-contact-picker-wizard/safari.jpg";
import advanced from "./ios-contact-picker-wizard/advanced.jpg";
import contactsApi from "./ios-contact-picker-wizard/contacts-api.jpg";
import experimental from "./ios-contact-picker-wizard/experimental.jpg";
import styled from "@emotion/styled";
import { ArrowDownward, FileDownloadDone, SettingsSuggestOutlined } from "@mui/icons-material";
import { ReactNode } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: scroll;
`;

const RowContainer = styled.div`
`;

const Image = styled.img`
  width: 100%;
`;
const Bold = styled.span`
    font-weight: bold;
`;
const CenteredArrow = styled(ArrowDownward)`
  align-self: center;
`;

const CenteredCheck = styled(FileDownloadDone)`
  align-self: center;
`;
const SpacedActionsContainer = styled.div`
    display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
`;

function OpenSettingsButton({children}: {children: ReactNode}) {
    return (
        <ActionButton variant="contained"
                      color="success"
                      href="App-prefs://prefs:root=Settings"
                      startIcon={<SettingsSuggestOutlined />}>
            {children}
        </ActionButton>
    )
}

export function IOsPickerEnableGuide({close}: {close: () => void}) {

    return (
        <>
            <DialogTitle>
                מתים על אייפון, אבל...
            </DialogTitle>
            <DialogContent>
                <Container>
                    באייפונים צריך לעשות עוד הגדרה קטנה:
                    <CenteredArrow />
                    <OpenSettingsButton>
                        פתחו את הגדרות המכשיר
                    </OpenSettingsButton>
                    <CenteredArrow />
                    <RowContainer>
                        <div>1. היכנסו להגדרות של <Bold>ספארי</Bold></div>
                        <Image src={safari} alt="safari settings" />
                    </RowContainer>
                    <CenteredArrow />
                    <RowContainer>
                        <div>2. גללו למטה <Bold>להגדרות מתקדמות</Bold></div>
                        <Image src={advanced} alt="advanced settings" />
                    </RowContainer>
                    <CenteredArrow />
                    <RowContainer>
                        <div>3. בחרו באפשרות האחרונה - <Bold>Experimental features</Bold></div>
                        <Image src={experimental} alt="safari settings" />
                    </RowContainer>
                    <CenteredArrow />
                    <RowContainer>
                        <div>4. גללו עד האופציה <Bold>Contact Picker API</Bold> (הרשומות מסודרות לפי א-ב׳) וסמנו אותה</div>
                        <Image src={contactsApi} alt="safari settings" />
                    </RowContainer>
                    <CenteredCheck />
                    <div><Bold>תתחדשו</Bold> על עוד יכולת לדפדפן 🎉 </div>
                    <SpacedActionsContainer>
                        <OpenSettingsButton>
                            לפתוח הגדרות
                        </OpenSettingsButton>
                        <ActionButton variant="outlined" onClick={close}>
                            לסגור
                        </ActionButton>
                    </SpacedActionsContainer>
                </Container>
            </DialogContent>
        </>
    )
}