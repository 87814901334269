import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import styled from "@emotion/styled";
import { ChromeIcon } from "../../../assets/ChromeIcon";
import { ActionButton } from "../styled-components/Buttons";


export function AndroidPickerEnableGuide() {
    return (
        <>
            <DialogTitle>
                יכולת זו עובדת רק ב-Chrome
            </DialogTitle>
            <DialogContent>
                  לחצו על הכפתור מטה כדי לפתוח את העמוד בדפדפן Google Chrome.
            </DialogContent>
            <DialogActions>
                <ActionButton variant="contained"
                              color="success"
                              href="intent://mumlatz.net/recommend#Intent;scheme=https;package=com.android.chrome;end"
                              startIcon={<ChromeIcon />}>
                    לפתוח בכרום
                </ActionButton>
            </DialogActions>
        </>
    )
}