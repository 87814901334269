import { UseQueryOptions } from "@tanstack/react-query";
import { api } from "../../../api";
import { AutoCompleteField } from "../../fields/AutoCompleteField";
import { Apartment } from "@mui/icons-material";

function useSearchCity(search: string, options?: UseQueryOptions<string[]>) {
    return api.Query.useSearchCitiesQuery({search}, options);
}

export interface ICityFieldProps {
    label: string,
    onChange: (data: string) => void,
    value: string,
}

export function CityField({...props}: ICityFieldProps) {
    return (
        <AutoCompleteField
            {...props}
            name="serviceAreaCity"
            useOptions={useSearchCity} 
            optionToString={x => x}
            multiple={false}
            icon={<Apartment />}
        />
    );
}