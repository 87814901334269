import { useSearchGeoItems } from "../hooks/geography";
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import { AutoCompleteInput } from "../validated-form-inputs/AutoCompleteInput";
import { IControlledFieldProps } from "../../business/business-fields/form-fields/types";
import { geoItemToString } from "../utils/geoUtils";

export function GeoItemsSearch({ control, name, label }: IControlledFieldProps) {
    return (
        <AutoCompleteInput
            useOptions={useSearchGeoItems}
            optionToString={geoItemToString}
            name={name || "serviceArea"}
            label={label || "אזורי שרות (ניתן לבחור כמה)"}
            control={control as any}
            icon={<EmojiTransportationIcon />}
            multiple={true}
        />
    )
}