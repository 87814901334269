import { SyntheticEvent, useState } from "react";
import { Autocomplete, CircularProgress, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "../../assets/SearchIcon";
import { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { debounce } from "../utils/newUtils";
import { AutocompleteValue } from '@mui/base/AutocompleteUnstyled/useAutocomplete';

export type ICommonInputProps = {}

interface IAutoCompleteInputProps<
    TOption,
    TOptionCategory,
    TMultiple extends boolean | undefined> extends ICommonInputProps {
    name: string,
    label: string,
    helpText?: string,
    useOptions: (search: string,
        option: UseQueryOptions<TOption[]>,
        category: TOptionCategory
    ) => UseQueryResult<TOption[]>,
    optionToString: (option: TOption) => string,
    optionCategory?: TOptionCategory,
    helperText?: string,
    disabled?: boolean,
    multiple?: TMultiple,
    icon?: JSX.Element,
    onChange: (data: AutocompleteValue<TOption, TMultiple, true, undefined>) => void,
    value?: AutocompleteValue<TOption, TMultiple, true, undefined>,
    // defaultValue?: AutocompleteValue<TOption, TMultiple, undefined, undefined>,
}

export function AutoCompleteField<
    TOption,
    TOptionCategory,
    TMultiple extends boolean | undefined>({
    useOptions,
    optionToString,
    helperText,
    name,
    label,
    optionCategory,
    icon,
    onChange,
    ...props
}: IAutoCompleteInputProps<TOption, TOptionCategory, TMultiple>) {
    const [searchValue, setSearchValue] = useState('');
    const [hasSearchQuery, setHasSearchQuery] = useState(false);
    const options = useOptions(searchValue, {
        enabled: searchValue?.length > 1
    }, optionCategory as TOptionCategory);

    const queryAutocompleteDebounced = debounce((_: SyntheticEvent, searchValue: string, reason: string) => {
        setHasSearchQuery(!(reason === "reset" || !searchValue.length))
        setSearchValue(reason === "reset" ? '' : searchValue);
    }, 500);

    return (
        <Autocomplete
            {...props}
            value={props.value}
            options={(options.data || []) as Array<TOption>}
            getOptionLabel={(option) => typeof option === 'string' ? option : optionToString(option)}
            isOptionEqualToValue={(option, value) => {
                return optionToString(option) === optionToString(value);
            }}
            onInputChange={queryAutocompleteDebounced}
            filterOptions={(x) => x}
            onChange={(event, data) => { onChange(data) }}
            loading={options.isFetching}
            loadingText="טוען..."
            noOptionsText={hasSearchQuery ? "לא נמצאו תוצאות" : "התחלו להקליד כדי לחפש"}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <>
                                <InputAdornment position="start">
                                    {options.isFetching ? <CircularProgress /> : (icon ?? <SearchIcon />)}
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                            </>
                        )
                    }}
                    label={label}
                    error={options.isError
                        || (!!searchValue && options.data?.length === 0 && !(options.isFetching))}
                    helperText={
                        options.isError ? 'משהו השתבש :('
                                        : searchValue && options.data?.length === 0 && !(options.isFetching)
                                          ? 'לא נמצאו תוצאות לחיפוש זה'
                                          : helperText}
                />
            )}
        />
    )
}
