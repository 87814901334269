import styled from "@emotion/styled";
import { humanizePhone } from "../../shared/utils/utils";
import React, { useMemo } from "react";
import { BadgeOutlined, LocationOnOutlined } from "@mui/icons-material";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useNavigate } from "react-router-dom";
import { IBusinessWithRecommendations, IPublicUser } from "../../api/axios-client";
import { CardContainer, IconAndTextInlineContainer } from "../../shared/components/styled-components/Containers";
import { PhoneDisplayField } from "../business-fields/display-fields/PhoneDisplayField";
import { AddressDisplayField } from "../business-fields/display-fields/AddressDisplayField";
import { ServiceAreaDisplayField } from "../business-fields/display-fields/ServiceAreaDisplayField";
import { ExternalLinkDisplayField } from "../business-fields/display-fields/ExternalLinkDisplayField";
import { DisplayCommonFields } from "../business-fields/display-fields/DisplayCommonFields";
import { commonFields, serviceProvidedAt } from "./businessCardHelpers";
import { ActionButton } from "../../shared/components/styled-components/Buttons";
import { trackEvent } from '../../analytics';

const TitleContainer = styled.div`
  display: grid;
  grid-gap: 14px;
  align-items: center;
  margin-bottom: 1rem;
`;
const TitleContent = styled.div`
  display: grid;
  grid-gap: 0.5rem;
`;

const BusinessName = styled.div`
  font-weight: 600;
  font-size: 30px;
  line-height: 21px;
`;
const BusinessType = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
`;

const UniversalDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const RecommendationsContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const DetailsButtonContainer = styled.div`
  display: grid;

  .MuiButton-contained {
    font-size: 1.1rem;
    font-weight: 400;
  }
`;

const Recommenders = styled.div`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

const ContactDetailsContainer = styled.div`
  display: flex;
  gap: 1.2rem;
`;

const BottomContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
`;

const ServiceProvidedAtContainer = styled.div`
  font-size: 1rem;
`;
const ExternalLinkContainer = styled.div`
  margin-top: 1rem;
`;

const Delimiter = styled.hr`
  border-style: solid;
  border-color: ${p => p.theme.textColor}10;
`;

const BoldText = styled.span`
  font-weight: 600;
`;


type NamedUser = { firstName: string, lastName: string, mobilePhone: string };
const fullName = (user: NamedUser) => user.firstName
    ? `${user.firstName} ${user.lastName}`
    : humanizePhone(user.mobilePhone);


type IRecommendationProps = {
    business: IBusinessWithRecommendations;
    isFullDetails?: boolean;

}

export function BusinessCard({ business, isFullDetails, }: IRecommendationProps) {
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();

    const analyticProperties = {
        businessName: business.name,
        businessTypes: business.businessTypes.map(bt => bt.name).join(', '),
        numberOfRecommendations: business.recommendations.length,
    }

    const recommenders = useMemo<IPublicUser[]>(() => (
        business.recommendations
                .map(r => r.user)
                .sort((a, b) => {
                    const aName = fullName(a);
                    const bName = fullName(b);
                    if (aName.charAt(0).match(/[א-ת]/) || bName.charAt(0).match(/[a-zA-Z]/)) {
                        return -1;
                    } else return 1;
                })
    ), [business]);

    function RecommendationsString() {
        switch (recommenders.length) {
            case 0:
                return (
                    <div>"אין ממליצים על עסק זה"</div>
                );
            case 1:
                return (
                    <div> הומלץ על ידי <BoldText>{fullName(recommenders[0])}</BoldText></div>
                );
            case 2:
                return (
                    <div>  הומלץ על ידי <BoldText> {fullName(recommenders[0])} ו{fullName(recommenders[1])}</BoldText>
                </div>
                );
            default:
                const firstTwoRecommendersString = recommenders.slice(0, 2).map(r => fullName(r)).join(", ");
                return (
                    <div>הומלץ על ידי
                    <BoldText> {firstTwoRecommendersString}</BoldText> ועוד {recommenders.length - 2} חברים
                </div>
                );
        }
    }

    const redirectToBusinessDetails = () => {
        trackEvent("Redirect to Business Details", analyticProperties);
        navigate(`/business/${business.phoneNumber}`);
    }

    const serviceProvidedAtElement = serviceProvidedAt(business);

    const showCommonFields = Object.keys(commonFields(business)).length > 0;


    return (
        <CardContainer>
            <TitleContainer>
                <TitleContent>
                    <BusinessName id="BusinessNameInfo">{business.name}</BusinessName>
                    <BusinessType id="BusinessTypeInfo">
                        {business.businessTypes.map(bt => bt.name).join(', ')}
                    </BusinessType>
                    {business.addressInfo?.city &&
                        <IconAndTextInlineContainer id="BusinessCityInfo">
                            <LocationOnOutlined />
                            {business.addressInfo.city}
                        </IconAndTextInlineContainer>
                    }
                </TitleContent>
            </TitleContainer>
            <Delimiter />
            <UniversalDataContainer>
                <ContactDetailsContainer>
                    <PhoneDisplayField {...{ business }} />
                    {business.addressInfo?.city &&
                        <AddressDisplayField {...{ business, isFullDetails }} />
                    }
                </ContactDetailsContainer>

                {serviceProvidedAtElement &&
                    <ServiceProvidedAtContainer>
                        <div>שירות </div>
                        {serviceProvidedAtElement}
                    </ServiceProvidedAtContainer>
                }
            </UniversalDataContainer>


            {isFullDetails &&
                <>
                    <ExternalLinkContainer>
                        <ExternalLinkDisplayField {...{ business }} />
                    </ExternalLinkContainer>
                    {business.serviceArea && business.serviceArea.length > 0 &&
                        <>
                            <Delimiter />
                            <ServiceAreaDisplayField {...{ business }} />
                        </>

                    }

                    {showCommonFields &&
                        <>
                            <Delimiter />
                            <DisplayCommonFields {...{ business }} />
                        </>
                    }
                </>
            }
            {(!isFullDetails && !!recommenders.length) &&
                <>
                <Delimiter />
                <BottomContainer>

                    <RecommendationsContainer>
                        <Recommenders>
                            <RecommendationsString />
                        </Recommenders>
                    </RecommendationsContainer>
                    <DetailsButtonContainer>
                        <ActionButton variant="contained"
                                onClick={redirectToBusinessDetails}
                                startIcon={<BadgeOutlined />}
                                color="success"
                                size="medium">
                            לפרטים
                        </ActionButton>
                    </DetailsButtonContainer>
                </BottomContainer>
                </>
            }
        </CardContainer>
    )
}