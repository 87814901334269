import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import React, { FormEvent, useState } from "react";
import styled from "@emotion/styled";
import { internationalizePhone, isPhoneValid, } from "../shared/utils/utils";
import { Login, Phone } from "@mui/icons-material";
import { finalizeCodeToVerification, startPhoneVerification } from "./user.service";
import { useNavigateOnLogin } from "../router/AnyUserRoute";
import { ActionButtonOnBackground } from "../shared/components/styled-components/Buttons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Form = styled.form`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
`;

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function LoginPage() {
    const navigate = useNavigateOnLogin();
    const [stage, setStage] = useState<'sendPhone' | 'sendCode'>("sendPhone");
    const [shouldValidate, setShouldValidate] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [phone, setPhone] = useState<string>('');
    const [verificationId, setVerificationId] = useState<string | null>(null);
    const [code, setCode] = useState<string>('');
    const [showSubmitButton, setShowSubmitButton] = useState(true);

    const validatePhone = (phone: string) => {
        if (!isPhoneValid(phone)) {
            setError('מספר הטלפון לא תקין');
            return false;
        }
        setError(null);
        return true;
    }
    const validateCode = async (code: string) => {
        if (code.length === 6 && /^\d+$/.test(code)) {
            setError(null);
            return true;
        }
    }

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const phone = e.target.value;
        setPhone(phone);
        if (shouldValidate) validatePhone(phone);
    }
    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const code = e.target.value;
        setCode(code);
        if (shouldValidate) validateCode(code);
    }

    const sendPhone = async () => {
        if (stage === "sendPhone") {

            setShouldValidate(true);
            const isPhoneValid = validatePhone(phone);
            if (!isPhoneValid) {
                return;
            }

            try {
                setShowSubmitButton(false);
                const response = await startPhoneVerification(internationalizePhone(phone));
                if (response) {
                    setStage('sendCode');
                    setVerificationId(response.id);
                    setShouldValidate(false);
                } else {
                    setError('משהו השתבש :(')
                }

            } catch (e) {
                setError('שגיעה בשליחת הטלפון לאימות, אנה נסו שנית');
            } finally {
                setShowSubmitButton(true);
            }
        }
    }

    const sendCode = async () => {
        setShouldValidate(true);
        const isCodeValid = await validateCode(code);
        if (!isCodeValid) {
            return;
        }
        if (!verificationId) {
            setError('שגיעה באימות הטלפון, אנה נסו שנית');
            return;
        }
        try {
            const { user } = await finalizeCodeToVerification(verificationId, code);
            if (user) {
                if (user.isShadow)
                    navigate('/signup');
                else
                    navigate('/');
            } else {
                navigate('/we-are-not-acquainted');
            }
        } catch (e) {
            setError('קוד אימות לא תקין');
        }

    }

    const onBlur = () => {
        setShouldValidate(true);
        stage === 'sendPhone' ? validatePhone(phone) : validateCode(code);
    }

    const sendButtonText = stage === 'sendPhone' ? 'כניסה' : 'אימות';

    const submitForm = (e: FormEvent) => {
        e.preventDefault();
        stage === 'sendPhone' ? sendPhone() : sendCode();
    }

    const TelInput = <TextField
        id="phone-input"
        label="טלפון נייד"
        type="tel"
        autoComplete="tel"
        value={phone}
        error={!!error}
        helperText={error || ''}
        onChange={handlePhoneChange}
        onBlur={onBlur}
        InputProps={{
            endAdornment: (
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
            )
        }}
    />;
    const OneTimeCodeInput = <TextField
        id="sms-code-input"
        inputMode="numeric"
        label="קוד אימות"
        autoComplete="one-time-code"
        value={code}
        onChange={handleCodeChange}
        onBlur={onBlur}
        error={!!error}
        helperText={error || ''}
        inputProps={{ pattern: "[0-9]*" }}
    />;

    return (
        <Container>
            <Form id="login-form" onSubmit={submitForm}>
                {stage === "sendPhone" ? TelInput : OneTimeCodeInput}
                {showSubmitButton &&
                    <ActionButtonOnBackground id="submit-button"
                                  type="submit"
                                  variant="contained"
                                  size="medium"
                                  color="success"
                                  endIcon={<Login />}>
                        {sendButtonText}
                    </ActionButtonOnBackground>
                }
                {!showSubmitButton && <Centered><CircularProgress /></Centered>}
            </Form>
        </Container>
    );
}