import { ButtonProps } from "@mui/material";
import { ChipButton } from "../shared/components/styled-components/Buttons";

export type ITreeButtonProps = {
    isOpen: boolean;
} & ButtonProps;
export const TreeButton = ({ isOpen, ...props }: ITreeButtonProps) => {
    return (
        <ChipButton {...props} aria-expanded={isOpen} />
    );
}