import styled from "@emotion/styled";

const Container = styled.div`
    display: grid;
  height: 100%;
  align-content: center;
  justify-content: center;
`;
const HeaderContainer = styled.div`

`;

const Header = styled.h2`

`;
const TextContainer = styled.div`

`;

export function UnauthorizedPage() {
    return (
        <Container>
            <HeaderContainer>
                <Header>המספר שלך לא רשום לאף קבוצה :(</Header>
            </HeaderContainer>
            <TextContainer>
                אם לדעתך לא רשמו אותך בטעות, נא לפנות למנהל הקבוצה.
            </TextContainer>
        </Container>
    )
}