import { useSearchParams } from "react-router-dom";
import { QueryParamConfig } from "serialize-query-params";

export function useSearchParamsState<T>(
    searchParamName: string,
    config: QueryParamConfig<T | null | undefined, T | null | undefined>,
): readonly [
    searchParamsState: T | null | undefined,
    setSearchParamsState: (newState: T  | null | undefined) => void
] {
    const [searchParams, setSearchParams] = useSearchParams();

    const acquiredSearchParam = searchParams.get(searchParamName);

    const setSearchParamsState = (newState: string) => {
        const next = Object.assign(
            {},
            [...Array.from(searchParams.entries())].reduce(
                (o, [key, value]) => ({ ...o, [key]: value }),
                {}
            ),
            { [searchParamName]: newState }
        );
        setSearchParams(next);
    };


    return [config.decode(acquiredSearchParam) ?? config.default, x => setSearchParamsState(config.encode(x)?.toString() || '')];
}