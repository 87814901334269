import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Check } from "@mui/icons-material";

type IBusinessTypeAddedDialogProps = {
    businessTypeName: string;
    open: boolean;
    handleClose: () => void;
}

export function BusinessTypeAddedDialog({ businessTypeName, open, handleClose }: IBusinessTypeAddedDialogProps) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>סוג עסק "{businessTypeName}" הוסף בהצלחה 🥳</DialogTitle>
            <DialogContent>

                <p>
                    בקרוב, אחרי שנשלב אותו בעץ הקטגוריות, כולם יוכלו למצוא אותו בחיפוש 💪
                </p>
                <p>
                    עד אז, תודה על העזרה בהרחבת הקטגוריות של מומלץ ❤️
                </p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained" startIcon={<Check />}>מעולה</Button>
            </DialogActions>
        </Dialog>
    )
}