import styled from "@emotion/styled";
import { PhoneField } from "../../../business/business-fields/form-fields/PhoneField";
import { FormProvider, useForm } from "react-hook-form";
import { internationalizePhone, isPhoneValid, normalizePhone } from "../../../shared/utils/utils";
import { Form } from "../../../shared/components/styled-components/Form";
import { ActionButtonOnBackground } from "../../../shared/components/styled-components/Buttons";
import { PersonSearchOutlined } from "@mui/icons-material";
import { ContactPicker } from "../../../shared/components/contact-picker/ContactPicker";

type IFormValues = { phone: string };

const Container = styled.div`
  display: grid;
  grid-gap: 2rem;
`;

const DescriptionContainer = styled.div`
  font-size: 1.2rem;
`;

export function BusinessPhoneForm({ setBusinessPhone }: { setBusinessPhone: (phone: string, name?: string) => void }) {
    const methods = useForm<IFormValues>({ mode: 'onTouched' });
    const { handleSubmit, control, setValue, setError, formState } = methods;


    const submitValues = (phone: string, name?: string) => {
        const formattedPhone = internationalizePhone(normalizePhone(phone));
        setBusinessPhone(formattedPhone, name);
    }
    const onSubmit = (data: IFormValues) => {
        submitValues(data.phone);
    }

    const handlePickedContact = (contact: IContact[]) => {
        const firstContact = contact[0];
        if (firstContact.tel.length) {
            const isValid = isPhoneValid(firstContact.tel[0]);
            if (isValid) {
                submitValues(firstContact.tel[0], firstContact.name[0]);
            } else {
                setValue('phone', firstContact.tel[0])
                setError('phone', { type: 'manual', message: 'מספר טלפון לא תקין' });
                return;
            }
        }

    }

    return (
        <Container>
            <DescriptionContainer>
                קודם נבדוק האם עסק זה כבר קיים במאגר:
            </DescriptionContainer>
            <FormProvider {...methods} >
                <Form id="phone-form" onSubmit={handleSubmit(onSubmit)}>
                    <PhoneField control={control as any} label="מס׳ טלפון של העסק" />
                    <ContactPicker handleContacts={handlePickedContact} />
                    <ActionButtonOnBackground type="submit"
                                              variant="contained"
                                              color="success"
                                              size="large"
                                              disabled={!formState.isValid}
                                              startIcon={<PersonSearchOutlined />}>
                        לבדוק
                    </ActionButtonOnBackground>
                </Form>
            </FormProvider>
        </Container>
    )
}