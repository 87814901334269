import { IControlledFieldProps } from "./types";
import { CheckboxGroupInput } from "../../../shared/validated-form-inputs/CheckboxGroupInput";
import { createCheckboxOptions } from "../fieldUtils";

type IEducationStagesProps = { untilUniversity?: boolean } & IControlledFieldProps
export function EducationStagesField({ control, untilUniversity }: IEducationStagesProps) {
    const options = createCheckboxOptions([
        'preSchool',
        'elementarySchool',
        'middleSchool',
        'highSchool'
    ]);


    if (untilUniversity) {
        options.push(...createCheckboxOptions(['preUniversity', 'university']));
    }

    return (
        <CheckboxGroupInput
            {...{ options, control }}
            name="educationStages"
            label="רמה"
        />
    )
}