import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Control, useController } from "react-hook-form";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import styled from "@emotion/styled";


const Container = styled.div`
  
`;

const ErrorContainer = styled.div`
    color: #d32f2f; // TODO: use theme
  font-size: 0.8rem;
  margin-top: 0.5rem;
`;

const LabelContainer = styled.div<{isError: boolean}>`
  font-size: 1rem;
  margin-bottom: 0.8rem;
  color: ${p => p.isError ? p.theme.palette.error.main : 'rgba(0, 0, 0, 0.6)'};
`;
type IToggleButtonInputProps = {
    name: string;
    control: Control;
    options: { value: string, label: string | JSX.Element }[];
    rules?: RegisterOptions;
    label: string;
}
export function ToggleButtonInput({ name, control, options, rules, label }: IToggleButtonInputProps) {
    const {
        field,
        fieldState: { error }
    } = useController({ name, control, rules, defaultValue: '' });

    return (
        <Container>
            <LabelContainer isError={!!error}>{label}{rules?.required ? ' *' : ''}</LabelContainer>
            <ToggleButtonGroup
                {...field}
                exclusive
                aria-label={label}
            >
                {options.map(option => (
                    <ToggleButton id={`${name}-${option.value}`} value={option.value} aria-label={option.value} key={option.value}>
                        {option.label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            {error && <ErrorContainer>{error.message}</ErrorContainer>}
        </Container>
    );
}